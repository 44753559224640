export default {
  lang: "中文",
  search: "搜索",
  searchPlaceholder: "请输入完整的合约地址",
  wallet: "钱包",
  walletLogin: "钱包登录",
  login: "登录",
  logined: "已登录",
  home: "首页",
  consulting: "资讯",
  memberCenter: "会员中心",
  memberCenter2: "会员中心",
  newLive: "新币直播",
  potentialCurrency: "潜力新币",
  popularCoins: "热门新币",
  tradingHotspots: "交易热点",
  VIPSelection: "VIP精选",
  profitStatistics: "盈利统计",
  more: "更多",
  checkMore: "查看更多",
  marketCap: "市值",
  mobility: "流动性",
  txns: "交易笔数",
  holders: "持有人数",
  volume: "交易量",
  buys: "购买",
  sells: "出售",
  txns24: "24h交易数",
  volume24: "24h成交量",
  dataInformation: "数据信息",
  collectionSuccessful: "收藏成功",
  shareSuccessful: "分享链接已复制到剪贴板",
  contractCode: "合约代码",
  membershipDescription: "会员说明",
  membershipExpiration: "会员到期",
  upgradeMembership: "升级会员",
  accountBalance: "账户余额",
  withdrawable: "可提现",
  withdrawal: "我要提现",
  accumulatedIncome: "累计收益",
  pendingReview: "待审核提现",
  myTeam: "我的团队",
  inviteFriends: "邀请好友",
  inviteToEarnProfits: "邀请好友赚收益",
  myCollection: "我的收藏",
  helpCenter: "帮助中心",
  openMembership: "开通会员",
  openTip: "开通平台会员，畅享会员好内容",
  membershipBenefits: "享优质专栏，会员专属资讯",
  highQualityColumn: "优质专栏",
  newCoinRecommendation: "新币推荐",
  exclusiveCustomerService: "专属客服",
  greatValueDiscounts: "超值优惠",
  memberBenefits: "会员权益",
  authorizationContract: "授权合约",
  pay: "支付",
  authorizationSuccessful: "授权成功",
  authorizationFailed: "授权失败",
  installMetaMask: "请安装 MetaMask 钱包插件",
  transactionSent: "交易已发送",
  transactionConfirmed: "交易已确认",
  transactionFailed: "交易失败",
  wantWithdrawal: "我要提现",
  withdrawalInstructions: "提现说明",
  withdrawTip: "请输入您要提现数量",
  minimum: "最低",
  remaining: "剩余",
  WithdrawToCurrentAccount: "提现到当前账户",
  serviceCharge: "手续费",
  notice: "注意",
  inputWwithdrawalAmount: "请输入提现数量",
  withdrawalCannotLess: "提现数量不能小于",
  withdrawalSuccessful: "提现成功",
  accumulatedMembershipDevelopment: "已累计发展会员",
  accumulatedIncome: "累计收益",
  user: "用户",
  profit: "收益",
  noTeam: "暂无团队",
  name: "名称",
  price: "价格",
  cancel: "取消",
  noCollection: "暂无收藏",
  time: "时间",
  riskTip: "币圈有风险 应谨慎至上",
  noData: "暂无数据",
  copySuccess: "复制成功",
  WalletPlugInNotInstalled: "未安装钱包插件",
  openVipTip: "请先开通VIP",
  loginWallet: "请先登录钱包",
  loading: "加载中...",
  noMore: "没有更多了",
  views: "浏览量",
  backList: "返回列表",
  pushTime: "推送时间",
  insufficientBalance: "余额不足",
  failedObtainBalance: "获取余额失败",
};
