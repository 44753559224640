<template>
  <div v-if="checkIsMobile">
    <topInfo></topInfo>
    <div class="body-box">
      <van-skeleton :row="20" :loading="loading">
        <div class="info-wrap">
          <div class="header-info fc">
            <img class="icon" :src="detail.info?.imageUrl || require('@/assets/img/head.png')" alt="">
            <div class="fs-20 fw-b yc-more" style="width: 230px;">{{ detail.baseToken?.name }}</div>
          </div>
          <div class="info-box fc-ae">
            <div>${{ detail.priceUsd }}</div>
            <div class="green-bg-box fr-ac-jc" :style="detail.priceChange?.h24 < 0 ? 'background: #FF0000;' : ''">
              <img :class="detail.priceChange?.h24 < 0 ? 'rotate' : ''" class="top-icon" src="@/assets/img/icon_top.png"
                alt="">
              <p>{{ detail.priceChange?.h24.toFixed(2) }}%</p>
            </div>
            <div class="info fr-ac">
              <div>{{ detail.baseToken?.symbol }}/<span class="gray">{{ detail.chainId }}</span>&nbsp; </div>
              <img @click="copy(address)" style="margin-right: 14px;" src="@/assets/img/icon_copy.png" alt="">
              <img src="@/assets/img/icon_time.png" alt="">
              <div class="gray">{{ getTime(detail.pairCreatedAt) }}&nbsp;ago</div>
              <img style="margin: 0 3px 0 14px;" v-if="view_count" src="@/assets/img/icon_eye.png" alt="">
              <div class="gray" v-if="view_count">{{ view_count }}</div>
            </div>
            <div class="status-box fr-ac">
              <div class="fc-ac-jc status-item" @click="selectTime(0)">
                <div class="status-item-time" :class="timeIndex == 0 ? 'active' : ''">5M</div>
                <div :class="detail.priceChange?.m5 > 0 ? 'green' : 'red'">{{ detail.priceChange?.m5 > 0 ?
    '+' +
    detail.priceChange?.m5 : detail.priceChange?.m5 }}%</div>
              </div>
              <div class="line"></div>
              <div class="fc-ac-jc status-item" @click="selectTime(1)">
                <div class="status-item-time" :class="timeIndex == 1 ? 'active' : ''">1H</div>
                <div :class="detail.priceChange?.h1 > 0 ? 'green' : 'red'">{{ detail.priceChange?.h1 > 0 ? '+' +
    detail.priceChange?.h1 : detail.priceChange?.h1 }}%</div>
              </div>
              <div class="line"></div>
              <div class="fc-ac-jc status-item" @click="selectTime(2)">
                <div class="status-item-time" :class="timeIndex == 2 ? 'active' : ''">6H</div>
                <div :class="detail.priceChange?.h6 > 0 ? 'green' : 'red'">{{ detail.priceChange?.h6 > 0 ? '+' +
    detail.priceChange?.h6 : detail.priceChange?.h6 }}%</div>
              </div>
              <div class="line"></div>
              <div class="fc-ac-jc status-item" @click="selectTime(3)">
                <div class="status-item-time" :class="timeIndex == 3 ? 'active' : ''">24H</div>
                <div :class="detail.priceChange?.h24 > 0 ? 'green' : 'red'">{{ detail.priceChange?.h24 > 0 ? '+' +
    detail.priceChange?.h24 : detail.priceChange?.h24 }}%</div>
              </div>
            </div>
            <div class="fr-ac data-box">
              <div class="fc data-left-box">
                <div class="gray" style="margin-bottom: 4px;">{{ $t('volume') }}</div>
                <div>${{ formattedVolume }}</div>
              </div>
              <div class="fc data-right-box">
                <div class="fr-ac-jsb gray" style="margin-bottom: 4px;">
                  <div>{{ $t('buys') }}</div>
                  <div>{{ $t('sells') }}</div>
                </div>
                <div class="fr-ac-jsb" style="margin-bottom: 4px;">
                  <div>{{ txnsBuys }}</div>
                  <div>{{ txnsSells }}</div>
                </div>
                <div class="fr-ac-jsb">
                  <div class="green-progress" :style="' width: ' + txnsBuysPercent + '%;'"></div>
                  <div class="red-progress" :style="' width: ' + txnsSellsPercent + '%;'"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="function-wrap fr-ac">
            <div class="function-box fr-ac-jc" @click="collectDetail()">
              <img src="@/assets/img/collect.png" alt="">
            </div>
            <div class="function-box fr-ac-jc" @click="toShare()">
              <img src="@/assets/img/share.png" alt="">
            </div>
          </div>
        </div>
      </van-skeleton>
      <div class="dex-screener">
        <iframe :src="dexUrl" width="100%" height="600px" frameborder="0" title="DEX Screener"></iframe>
        <div class="data-info-title active">{{ $t('dataInformation') }}</div>
        <van-row gutter="60" style="padding: 0 15px 15px;">
          <van-col span="12">
            <div class="fr-ac-jsb mt-10">
              <div class="fw-b">{{ $t('marketCap') }}</div>
              <div class="light-gray">${{ formattedMarketCap }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="fr-ac-jsb mt-10">
              <div class="fw-b">{{ $t('mobility') }}</div>
              <div class="light-gray">${{ formattedLiquidity }}</div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <!-- <div style="padding: 0 15px;">
      <div class="transaction-tab-box fr-ac">
        <div class="transaction-tab-item" :class="{ 'active': transactionTabActive === index }"
          @click="transactionTabActive = index" v-for="(item, index) in transactionTab" :key="index">{{ item }}</div>
      </div>
      <div class="transaction-table-top"></div>
      <div class="table-head fr-ac">
        <van-col span="2">
          <div class="table-head-title">时间</div>
        </van-col>
        <van-col span="4">
          <div class="table-head-title">类型</div>
        </van-col>
        <van-col span="5">
          <div class="table-head-title">交易总金额</div>
        </van-col>
        <van-col span="3">
          <div class="table-head-title">数量</div>
        </van-col>
        <van-col span="4">
          <div class="table-head-title">价格</div>
        </van-col>
        <van-col span="6">
          <div class="table-head-title">交易者</div>
        </van-col>
      </div>
      <div class="table-body">
        <div class="table-row fr-ac" v-for="(item, index) in 9" :key="index">
          <van-col span="2">
            <div class="gray ta-c">2s</div>
          </van-col>
          <van-col span="4">
            <div class="green ta-c">Bug</div>
          </van-col>
          <van-col span="5">
            <div class="green ta-c">$69.41</div>
          </van-col>
          <van-col span="3">
            <div class="green ta-c">28.7</div>
          </van-col>
          <van-col span="4">
            <div class="green ta-c">$69.41</div>
          </van-col>
          <van-col span="6">
            <div class="ta-c">vjetEU</div>
          </van-col>
        </div>
      </div>
    </div> -->
  </div>
  <div v-else class="fc-ac common">
    <div class="body-box">
      <van-skeleton :row="20" :loading="loading">
        <div class="info-wrap-pc fr">
          <div class="black-bg"></div>
          <div class="left-box fc">
            <div class="header-info fr-ae">
              <img class="icon" :src="detail.info?.imageUrl || require('@/assets/img/head.png')" alt="">
              <div class="fs-28 fw-b ml-10" style="font-size: 28px;">{{ detail.baseToken?.name }}</div>
            </div>
            <div class="info-box fc-ae">
              <div class="fr-ac">
                <div class="mr-20 fs-18 fw-b">${{ detail.priceUsd }}</div>
                <div class="green-bg-box fr-ac-jc" style="margin-top: 0"
                  :style="detail.priceChange?.h24 < 0 ? 'background: #FF0000;' : ''">
                  <img :class="detail.priceChange?.h24 < 0 ? 'rotate' : ''" class="top-icon"
                    src="@/assets/img/icon_top.png" alt="">
                  <p>{{ detail.priceChange?.h24.toFixed(2) }}%</p>
                </div>
              </div>

              <div class="info fr-ac-jsb">
                <div class="fr-ac">
                  <div>{{ detail.baseToken?.symbol }}/<span class="gray">{{ detail.chainId }}</span>&nbsp; </div>
                  <img class="pointer mr-20" @click="copy(address)" src="@/assets/img/icon_copy.png" alt="">
                  <img src="@/assets/img/icon_time.png" alt="">
                  <div class="gray">{{ getTime(detail.pairCreatedAt) }}&nbsp;ago</div>
                  <img style="margin: 0 6px 0 14px;" v-if="view_count" src="@/assets/img/icon_eye.png" alt="">
                  <div class="gray" v-if="view_count">{{ view_count }}&nbsp;{{ $t('views') }}</div>
                </div>
                <div class="fr-ac">
                  <div class="function-box fr-ac-jc" @click="collectDetail()">
                    <img src="@/assets/img/collect.png" alt="">
                  </div>
                  <div class="function-box fr-ac-jc" @click="toShare()">
                    <img src="@/assets/img/share.png" alt="">
                  </div>
                </div>
              </div>
              <iframe :src="dexUrl" width="100%" height="630px" frameborder="0" title="DEX Screener"></iframe>
            </div>
          </div>
          <div class="fc">
            <div class="right-top-box fc-ac">
              <div class="status-box fr-ac">
                <div class="fc-ac-jc status-item" @click="selectTime(0)">
                  <div class="status-item-time" :class="timeIndex == 0 ? 'active' : ''">5M</div>
                  <div style="font-size: 14px" :class="detail.priceChange?.m5 > 0 ? 'green' : 'red'">{{
    detail.priceChange?.m5 > 0 ? '+' +
      detail.priceChange?.m5 : detail.priceChange?.m5 }}%</div>
                </div>
                <div class="line"></div>
                <div class="fc-ac-jc status-item" @click="selectTime(1)">
                  <div class="status-item-time" :class="timeIndex == 1 ? 'active' : ''">1H</div>
                  <div style="font-size: 14px" :class="detail.priceChange?.h1 > 0 ? 'green' : 'red'">{{
    detail.priceChange?.h1 >
      0 ? '+' +
    detail.priceChange?.h1 : detail.priceChange?.h1 }}%</div>
                </div>
                <div class="line"></div>
                <div class="fc-ac-jc status-item" @click="selectTime(2)">
                  <div class="status-item-time" :class="timeIndex == 2 ? 'active' : ''">6H</div>
                  <div style="font-size: 14px" :class="detail.priceChange?.h6 > 0 ? 'green' : 'red'">{{
    detail.priceChange?.h6 > 0 ? '+' +
      detail.priceChange?.h6 : detail.priceChange?.h6 }}%</div>
                </div>
                <div class="line"></div>
                <div class="fc-ac-jc status-item" @click="selectTime(3)">
                  <div class="status-item-time" :class="timeIndex == 3 ? 'active' : ''">24H</div>
                  <div style="font-size: 14px" :class="detail.priceChange?.h24 > 0 ? 'green' : 'red'">{{
    detail.priceChange?.h24 > 0 ? '+' +
      detail.priceChange?.h24 : detail.priceChange?.h24 }}%</div>
                </div>
              </div>
              <div class="fr-ac data-box">
                <div class="fc data-left-box">
                  <div class="gray" style="margin-bottom: 4px;">{{ $t('volume') }}</div>
                  <div>${{ formattedVolume }}</div>
                </div>
                <div class="fc data-right-box">
                  <div class="fr-ac-jsb gray" style="margin-bottom: 4px;">
                    <div>{{ $t('buys') }}</div>
                    <div>{{ $t('sells') }}</div>
                  </div>
                  <div class="fr-ac-jsb" style="margin-bottom: 4px;">
                    <div>{{ txnsBuys }}</div>
                    <div>{{ txnsSells }}</div>
                  </div>
                  <div class="fr-ac-jsb">
                    <div class="green-progress" :style="' width: ' + txnsBuysPercent + '%;'"></div>
                    <div class="red-progress" :style="' width: ' + txnsSellsPercent + '%;'"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-bottom-box fc-ac">
              <div class="data-info-title active">{{ $t('dataInformation') }}</div>
              <div class="pl-40 pr-40 width-100">
                <div class="fr-ac-jsb width-100">
                  <div class="fw-b">{{ $t('marketCap') }}</div>
                  <div class="light-gray">${{ formattedMarketCap }}</div>
                </div>
                <div class="fr-ac-jsb width-100 mt-30">
                  <div class="fw-b">{{ $t('mobility') }}</div>
                  <div class="light-gray">${{ formattedLiquidity }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-skeleton>
    </div>
  </div>
</template>

<script>
import topInfo from "@/components/topInfo";
import topInfoPc from '@/components/topInfoPc'
import store from "@/store";
import { getDetail } from '@/api/api'
import { addCollect, getInviteAddress } from '@/api/member'
import i18n from '@/lang'
import { Toast } from "vant";
import { mapGetters } from 'vuex'
export default {
  components: {
    topInfo,
    topInfoPc,
  },
  data() {
    return {
      address: '',
      detail: {},
      dexUrl: '',
      transactionTab: ['全部交易', '重点交易'],
      transactionTabActive: 0, // 0 全部交易 1 重点交易
      volume: 0, // 交易量
      txnsBuys: 0, // 买入量
      txnsSells: 0, // 卖出量
      timeIndex: 1, // 时间索引
      loading: true,
      view_count: 0, // 浏览量
    }
  },
  computed: {
    ...mapGetters([
      'checkIsMobile',
    ]),
    getTime() {
      return (targetTimestamp) => {
        // 获取当前时间的时间戳
        const currentTimestamp = Date.now();

        // 计算时间差（单位：毫秒）
        const timeDifference = currentTimestamp - targetTimestamp;
        // 转换为小时
        const differenceInHours = Math.floor(timeDifference / 1000 / 60 / 60);
        let timeStr = '';
        if (differenceInHours > 24) {
          timeStr = Math.floor(differenceInHours / 24) + 'd'
        } else {
          timeStr = differenceInHours + 'h'
        }
        return timeStr
      }
    },
    //市值
    formattedMarketCap() {
      return this.$util.formatMarketCap(Number(this.detail.marketCap) || 0);
    },
    // 流动性
    formattedLiquidity() {
      return this.$util.formatMarketCap(Number(this.detail.liquidity?.usd) || 0);
    },
    // 交易量
    formattedVolume() {
      return this.$util.formatMarketCap(Number(this.volume) || 0);
    },
    // 计算 购买量 占百分比
    txnsBuysPercent() {
      const total = this.txnsBuys + this.txnsSells;
      return total === 0 ? 0 : ((this.txnsBuys / total) * 100).toFixed(2);  // 防止除零错误，保留两位小数
    },

    // 计算 出售量 占百分比
    txnsSellsPercent() {
      const total = this.txnsBuys + this.txnsSells;
      return total === 0 ? 0 : ((this.txnsSells / total) * 100).toFixed(2);  // 防止除零错误，保留两位小数
    }
  },
  watch: {
    // '$store.state.login.chain': function (val) {
    //   if (val === 'ETH') {
    //     this.dexUrl = 'https://dexscreener.com/ethereum/' + this.address + '?embed=1&theme=dark&trades=0&info=0';
    //   } else if (val === 'SOL') {
    //     this.dexUrl = 'https://dexscreener.com/solana/' + this.address + '?embed=1&theme=dark&trades=0&info=0';
    //   }
    // },
  },
  mounted() {
    this.address = this.$route.query.address;
    this.view_count = this.$route.query.view_count;
    if (this.$store.state.login.chain === 'ETH') {
      this.dexUrl = 'https://dexscreener.com/ethereum/' + this.address + '?embed=1&theme=dark&trades=0&info=0';
    } else if (this.$store.state.login.chain === 'SOL') {
      this.dexUrl = 'https://dexscreener.com/solana/' + this.address + '?embed=1&theme=dark&trades=0&info=0';
    }
    getDetail(this.address).then((res) => {
      this.detail = res.data.pairs[0]
      this.volume = this.detail.volume.h1
      this.txnsBuys = this.detail.txns.h1.buys
      this.txnsSells = this.detail.txns.h1.sells
      this.loading = false
    });
  },
  methods: {
    selectTime(index) {
      this.timeIndex = index
      switch (index) {
        case 0:
          this.volume = this.detail.volume.m5
          this.txnsBuys = this.detail.txns.m5.buys
          this.txnsSells = this.detail.txns.m5.sells
          break;
        case 1:
          this.volume = this.detail.volume.h1
          this.txnsBuys = this.detail.txns.h1.buys
          this.txnsSells = this.detail.txns.h1.sells
          break;
        case 2:
          this.volume = this.detail.volume.h6
          this.txnsBuys = this.detail.txns.h6.buys
          this.txnsSells = this.detail.txns.h6.sells
          break;
        case 3:
          this.volume = this.detail.volume.h24
          this.txnsBuys = this.detail.txns.h24.buys
          this.txnsSells = this.detail.txns.h24.sells
          break;
        default:
          break;
      }
    },
    //收藏
    collectDetail() {
      let data = {
        name: this.detail.baseToken.name,
        price: this.detail.priceUsd,
        market_price: this.detail.marketCap,
        flow_price: this.detail.liquidity.usd,
        btc_id: this.address,
        rate: this.detail.priceChange.h24,
        buys_h24: this.detail.txns.h24.buys,
        sells_h24: this.detail.txns.h24.sells,
        volume_h24: this.detail.volume.h24,
        view_count: this.view_count
      }
      addCollect(data).then(res => {
        if (res.data.code == 0) {
          Toast(this.$t('collectionSuccessful'))
        }
      })
    },
    toShare() {
      getInviteAddress().then(async (res) => {
        // await navigator.clipboard.writeText(res.data.data.url);
        let copy = res.data.data.url
        // 创建一个隐藏的 textarea 元素
        const textArea = document.createElement('textarea');
        textArea.value = copy;
        document.body.appendChild(textArea);

        // 选择文本
        textArea.select();
        textArea.setSelectionRange(0, 99999);  // 对于移动设备需要设置范围

        // 执行复制操作
        try {
          document.execCommand('copy');
          Toast(this.$t('shareSuccessful'))
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }

        // 移除临时的 textarea 元素
        document.body.removeChild(textArea);
      })
    },
    async copy(copy) {
      // try {
      //   await navigator.clipboard.writeText(copy);
      //   Toast(this.$t('copySuccess'))
      // } catch (err) {
      //   console.log('复制失败:', err);
      // }

      // 创建一个隐藏的 textarea 元素
      const textArea = document.createElement('textarea');
      textArea.value = copy;
      document.body.appendChild(textArea);

      // 选择文本
      textArea.select();
      textArea.setSelectionRange(0, 99999);  // 对于移动设备需要设置范围

      // 执行复制操作
      try {
        document.execCommand('copy');
        Toast(this.$t('copySuccess'))
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }

      // 移除临时的 textarea 元素
      document.body.removeChild(textArea);
    }
  }
}
</script>

<style lang="less" scoped>
.body-box {
  padding: 0 10px;
}

.info-wrap {
  width: 100%;
  background: #292B28;
  border-radius: 7px 7px 17px 17px;
  padding: 35px 5px 5px;
  margin-top: 25px;
  position: relative;

  .header-info {
    position: absolute;
    top: 8px;
    left: 16px;

    .icon {
      width: 56px;
      height: 56px;
      margin-bottom: 4px;
      border-radius: 50%;
    }
  }

  .info-box {
    width: 100%;
    padding: 15px 15px 34px;
    background: #FFFCFC;
    border-radius: 7px 17px 17px 17px;

    .info {
      width: 100%;
      height: 29px;
      background: rgba(95, 98, 107, 0.08);
      border-radius: 15px;
      padding-left: 9px;
      line-height: 1;
      margin-top: 18px;
      margin-bottom: 16px;

      img {
        width: 12px;
        height: 12px;
      }
    }

    .status-box {
      width: 100%;
      height: 56px;
      background: #F2F0F1;
      border-radius: 7px;
      border: 1px solid #DDDDDD;

      .status-item {
        width: 25%;
        font-weight: bold;

        .status-item-time {
          font-size: 18px;
          color: #010000;
          margin-bottom: 8px;
          position: relative;
          z-index: 0;

          &.active::after {
            width: 30px;
            height: 5px;
            content: '';
            background: #70ED24;
            border-radius: 5px;
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
          }
        }
      }

      .line {
        height: 56px;
        width: 1px;
        background: #DDDDDD;
      }
    }

    .data-box {
      width: 100%;
      padding-top: 15px;

      .data-left-box {
        width: 25%;
      }

      .data-right-box {
        width: 75%;
        padding-left: 15px;
        border-left: 1px solid #DDDDDD;
      }

      .green-progress {
        height: 6px;
        background-color: #70ED24;
        border-radius: 6px 0 0 6px;
      }

      .red-progress {
        height: 6px;
        background-color: #FF0000;
        border-radius: 0 6px 6px 0;
      }
    }
  }

  .function-wrap {
    position: absolute;
    right: 9px;
    bottom: -19px;

    .function-box {
      width: 38px;
      height: 38px;
      background: #292B28;
      border-radius: 5px;
      margin-left: 2px;

      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.dex-screener {
  margin-top: 30px;
  border: 5px solid #292B28;
  border-radius: 7px;
  padding: 5px;
  margin-bottom: 30px;

  iframe {
    border: none;
    /* 可调整 iframe 的大小 */
    max-width: 100%;
    border-radius: 7px;
  }

  .data-info-title {
    width: 324px;
    height: 32px;
    line-height: 32px;
    background: #F2F0F1;
    border-radius: 16px;
    margin: 26px 0 20px;
    padding-left: 10px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    z-index: 0;

    &.active::after {
      width: 64px;
      height: 5px;
      content: '';
      background: #70ED24;
      border-radius: 5px;
      position: absolute;
      bottom: 8px;
      left: 10px;
      z-index: -1;
    }
  }
}

// .transaction-tab-box {
//   padding: 31px 0 12px 20px;

//   .transaction-tab-item {
//     font-weight: bold;
//     font-size: 16px;
//     color: #666666;
//     margin-right: 36px;
//     position: relative;
//     z-index: 0;

//     &.active {
//       color: #000000;
//     }

//     &.active::after {
//       width: 64px;
//       height: 5px;
//       content: '';
//       background: #70ED24;
//       border-radius: 5px;
//       position: absolute;
//       bottom: 2px;
//       left: 50%;
//       transform: translateX(-50%);
//       z-index: -1;
//     }
//   }
// }

// .transaction-table-top {
//   width: 345px;
//   height: 10px;
//   background-color: #70ED24;
//   border-top: 2px solid #292B28;
//   margin-bottom: 14px;
// }

// .table-head {
//   width: 100%;
//   height: 50px;
//   border-bottom: 1px solid #E5E5E5;

//   .table-head-title {
//     font-size: 12px;
//     position: relative;
//     text-align: center;
//     color: #666666;
//   }

//   .green-circle {
//     width: 9px;
//     height: 9px;
//     background: #70ED24;
//     border-radius: 50%;
//     margin-left: 10px;
//   }
// }

// .table-body {
//   width: 345px;
//   background: #FFFFFF;
//   margin: 0 auto;

//   .table-row {
//     height: 50px;
//     border-bottom: 1px solid #E5E5E5;
//   }

//   .icon-img {
//     width: 26px;
//     height: 26px;
//     margin-right: 7px;
//   }

//   .status-img {
//     width: 15px;
//     height: 15px;
//     margin-left: 10px;
//   }
// }

//pc端
.info-wrap-pc {
  width: 100%;
  border-radius: 14px;
  margin-top: 50px;
  position: relative;
  padding-top: 45px;

  div {
    font-size: 18px;
  }

  .black-bg {
    background: #292B28;
    border-radius: 14px;
    width: 100%;
    height: 109px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .left-box {
    width: 883px;
    height: 846px;
    background: #FFFFFF;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 14px;
    border: 4px solid #292B28;
    position: relative;
    margin-right: 12px;

    .header-info {
      position: absolute;
      top: -25px;
      left: 39px;

      .icon {
        width: 68px;
        height: 68px;
        margin-bottom: 4px;
        border-radius: 50%;
      }
    }

    .info-box {
      width: 100%;
      padding: 15px 15px 34px;
      background: #FFFCFC;
      border-radius: 7px 17px 17px 17px;

      .info {
        width: 100%;
        height: 50px;
        background: rgba(95, 98, 107, 0.08);
        border-radius: 25px;
        padding: 0 28px;
        line-height: 1;
        margin-top: 32px;
        margin-bottom: 45px;
        font-size: 18px;

        img {
          width: 24px;
          height: 24px;
        }

        .function-box {
          width: 38px;
          height: 38px;
          background: #292B28;
          border-radius: 5px;
          margin-left: 2px;
          cursor: pointer;

          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    iframe {
      border: none;
      /* 可调整 iframe 的大小 */
      max-width: 100%;
      border-radius: 7px;
    }
  }

  .right-top-box {
    width: 386px;
    height: 243px;
    background: #FFFFFF;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 14px;
    border: 4px solid #292B28;
    padding: 22px 24px 0;

    .status-box {
      width: 100%;
      height: 77px;
      background: #F2F0F1;
      border-radius: 7px;
      border: 1px solid #DDDDDD;
      cursor: pointer;
      margin-bottom: 32px;

      .status-item {
        width: 25%;
        font-weight: bold;

        .status-item-time {
          font-size: 24px;
          color: #010000;
          margin-bottom: 8px;
          position: relative;
          z-index: 0;

          &.active::after {
            width: 30px;
            height: 5px;
            content: '';
            background: #70ED24;
            border-radius: 5px;
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
          }
        }
      }

      .line {
        height: 77px;
        width: 1px;
        background: #DDDDDD;
      }
    }

    .data-box {
      width: 100%;
      padding-top: 15px;

      .data-left-box {
        width: 25%;
        padding-right: 15px;
      }

      .data-right-box {
        width: 75%;
        padding-left: 15px;
        border-left: 1px solid #DDDDDD;
      }

      .green-progress {
        height: 6px;
        background-color: #70ED24;
        border-radius: 6px 0 0 6px;
      }

      .red-progress {
        height: 6px;
        background-color: #FF0000;
        border-radius: 0 6px 6px 0;
      }
    }
  }

  .right-bottom-box {
    margin-top: 13px;
    width: 386px;
    height: 590px;
    background: #FFFFFF;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 14px;
    border: 4px solid #292B28;

    .data-info-title {
      width: 353px;
      height: 56px;
      line-height: 56px;
      background: #F2F0F1;
      border-radius: 8px;
      margin: 26px 0 46px;
      padding-left: 20px;
      font-weight: bold;
      font-size: 24px;
      position: relative;
      z-index: 0;

      &.active::after {
        width: 95px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        bottom: 18px;
        left: 20px;
        z-index: -1;
      }
    }
  }
}
</style>
