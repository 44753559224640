<template>
    <div>
        <div class="pt-50">
            <el-carousel :interval="4000" type="card">
                <el-carousel-item v-for="item in bannerList" :key="item.id">
                    <img class="swiper-img" :src="item.images" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>

        <!-- 新币直播 -->
        <div class="title-box fr-ac-jsb">
            <div class="title">{{ $t('newLive') }}</div>
            <div class="more-btn fr-ac-jc" @click="changeTab(1)">
                <p>{{ $t('more') }}</p>
                <img src="@/assets/img/right_black.png" alt="">
            </div>
        </div>
        <div class="fr-ac-jsb width-100" v-if="xbDataList && xbDataList.length > 0">
            <div v-for="(item, index) in xbDataList" :key="index" class="">
                <div class="hot-list-box" @click="toDetail(item.token_contract, item.view_count)">
                    <div class="white-bg"></div>
                    <div class="content-box">
                        <van-row gutter="16">
                            <van-col span="6">
                                <img class="label-icon" src="@/assets/img/head.png" alt="">
                            </van-col>
                            <van-col span="18" class="fc-jsb" style="height: 70px;">
                                <div class="hot-title yc-more" style="overflow-wrap: break-word;">{{ item.token_name
                                    }}
                                </div>
                                <div class="fr-ac-jsb">
                                    <div class="fw-b fs-18">${{ item.price.toFixed(6) }}</div>
                                    <!-- <div style="color: #999999;">22d</div> -->
                                </div>
                            </van-col>
                        </van-row>
                        <van-row class="hot-center-box">
                            <van-col span="8">
                                <div class="mb-10 fs-18">${{ item.open_price.toFixed(6) }}</div>
                                <div class="green-bg-box fr-ac-jc"
                                    :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                    <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                        src="@/assets/img/icon_top.png" alt="">
                                    <p style="font-size: 18px;">{{ item.price_diff.toFixed(0) }}%</p>
                                </div>
                            </van-col>
                            <!-- <van-col span="14">
                                    <div class="hot-chart" id="chart"></div>
                                </van-col> -->
                        </van-row>
                        <van-row>
                            <div class="fr-ac-jsb mt-30">
                                <div class="fw-b">{{ $t('marketCap') }}</div>
                                <div class="light-gray">${{ formattedMarketCap(item.market_cap) }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-30">
                                <div class="fw-b">{{ $t('mobility') }}</div>
                                <div class="light-gray">${{ formattedMarketCap(item.liquidity) }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-30">
                                <div class="fw-b">{{ $t('txns') }}</div>
                                <div class="light-gray">{{ item.txcount_24h || 0 }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-30">
                                <div class="fw-b">{{ $t('holders') }}</div>
                                <div class="light-gray">{{ item.holders || 0 }}</div>
                            </div>
                        </van-row>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <van-empty :description="$t('noData')" />
        </div>

        <!-- 新币 -->
        <div class="new-currency-tab-wrap fr-ac-jc">
            <div class="new-currency-tab-item fr-ac-jc" :class="{ 'active': 0 === curNewCurrencyIndex }"
                @click="changeNewCurrencyTab(0)">{{ $t('potentialCurrency')
                }}</div>
            <div class="new-currency-tab-item fr-ac-jc" :class="{ 'active': 1 === curNewCurrencyIndex }"
                @click="changeNewCurrencyTab(1)">{{ $t('popularCoins')
                }}</div>
        </div>
        <div>
            <div v-if="qlrmDataList && qlrmDataList.length > 0" class="fr-ac-jsb fw">
                <div v-for="(item, index) in qlrmDataList" :key="index">
                    <div class="new-currency-bg">
                        <div class="new-currency-content-center"
                            @click="toDetail(item.token_contract || item.address, item.view_count)">
                            <van-row gutter="14" class="info-top fr-ac">
                                <van-col span="4">
                                    <img class="label-icon" src="@/assets/img/head.png" alt="">
                                </van-col>
                                <van-col span="14" class="fc-jsb" style="height: 60px;">
                                    <div class="hot-title">{{ item.token_name || item.symbol }}</div>
                                    <div class="gray fs-16" v-if="item.token_symbol">{{ item.token_symbol }}</div>
                                </van-col>
                                <!-- <van-col span="9">
                                <div class="hot-chart" id="new-currency-chart"></div>
                            </van-col> -->
                                <van-col span="6">
                                    <div class="fs-18">${{ item.price.toFixed(6) }}</div>
                                    <div class="green-bg-box fr-ac-jc" v-if="curNewCurrencyIndex === 0"
                                        :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                        <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                            src="@/assets/img/icon_top.png" alt="">
                                        <p>{{ item.price_diff.toFixed(0) }}%</p>
                                    </div>
                                    <div class="green-bg-box fr-ac-jc" v-else
                                        :style="item.price_change_percent < 0 ? 'background: #FF0000;' : ''">
                                        <img :class="item.price_change_percent < 0 ? 'rotate' : ''" class="top-icon"
                                            src="@/assets/img/icon_top.png" alt="">
                                        <p>{{ item.price_change_percent.toFixed(0) }}%</p>
                                    </div>
                                </van-col>
                            </van-row>
                            <van-row gutter="60" class="pl-30 pr-30">
                                <van-col span="12" class="mt-20">
                                    <div class="fr-ac-jsb mt-10">
                                        <div class="fw-b">{{ $t('marketCap') }}</div>
                                        <div class="light-gray">${{ formattedMarketCap(item.market_cap) }}</div>
                                    </div>
                                    <div class="fr-ac-jsb mt-20">
                                        <div class="fw-b">{{ $t('mobility') }}</div>
                                        <div class="light-gray">${{ formattedMarketCap(item.liquidity) }}</div>
                                    </div>
                                </van-col>
                                <van-col span="12" class="mt-20">
                                    <div class="fr-ac-jsb mt-10">
                                        <div class="fw-b">{{ $t('txns') }}</div>
                                        <div class="light-gray">{{ item.txcount_24h || item.swaps || 0 }}</div>
                                    </div>
                                    <div class="fr-ac-jsb mt-20">
                                        <div class="fw-b">{{ $t('holders') }}</div>
                                        <div class="light-gray">{{ item.holders || item.holder_count || 0 }}</div>
                                    </div>
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <van-empty :description="$t('noData')" />
            </div>
            <div class="new-currency-content-top fr-ac-jc">
                <div class="fr-ac" @click="changeTab(2)">
                    <p>{{ $t('checkMore') }}</p>
                    <img src="@/assets/img/right_green.png" alt="">
                </div>
            </div>
        </div>

        <!-- 交易热点 -->
        <div class="title-box fr-ac-jsb">
            <div class="title">{{ $t('tradingHotspots') }}</div>
            <div class="more-btn fr-ac-jc" @click="changeTab(4)">
                <p>{{ $t('more') }}</p>
                <img src="@/assets/img/right_black.png" alt="">
            </div>
        </div>
        <div v-if="rdDataList && rdDataList.length > 0" class="fr-ac-jsb fw">
            <div v-for="(item, index) in rdDataList" :key="index"
                @click="toDetail(item.token_contract, item.view_count)">
                <div class="new-currency-bg">
                    <div class="new-currency-content-center">
                        <van-row gutter="14" class="info-top fr-ac">
                            <van-col span="4">
                                <img class="label-icon" src="@/assets/img/head.png" alt="">
                            </van-col>
                            <van-col span="14" class="fc-jsb" style="height: 60px;">
                                <div class="hot-title">{{ item.token_name }}</div>
                                <div class="gray fs-16">{{ item.token_symbol }}</div>
                            </van-col>
                            <!-- <van-col span="9">
                            <div class="hot-chart" id="new-currency-chart"></div>
                        </van-col> -->
                            <van-col span="6">
                                <div class="fs-18">${{ item.price.toFixed(6) }}</div>
                                <div class="green-bg-box fr-ac-jc"
                                    :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                    <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                        src="@/assets/img/icon_top.png" alt="">
                                    <p>{{ item.price_diff.toFixed(0) }}%</p>
                                </div>
                                <!-- <div class="green-bg-box fr-ac-jc">
                                <img class="top-icon" src="@/assets/img/icon_top.png" alt="">
                                <p>13.1%</p>
                            </div> -->
                            </van-col>
                        </van-row>
                        <van-row gutter="60" class="pl-30 pr-30">
                            <van-col span="12" class="mt-20">
                                <div class="fr-ac-jsb mt-10">
                                    <div class="fw-b">{{ $t('marketCap') }}</div>
                                    <div class="light-gray">${{ formattedMarketCap(item.market_cap) }}</div>
                                </div>
                                <div class="fr-ac-jsb mt-20">
                                    <div class="fw-b">{{ $t('mobility') }}</div>
                                    <div class="light-gray">${{ formattedMarketCap(item.liquidity) }}</div>
                                </div>
                            </van-col>
                            <van-col span="12" class="mt-20">
                                <div class="fr-ac-jsb mt-10">
                                    <div class="fw-b">{{ $t('txns') }}</div>
                                    <div class="light-gray">{{ item.txcount_24h || 0 }}</div>
                                </div>
                                <div class="fr-ac-jsb mt-20">
                                    <div class="fw-b">{{ $t('holders') }}</div>
                                    <div class="light-gray">{{ item.holders || 0 }}</div>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <van-empty :description="$t('noData')" />
        </div>

        <!-- VIP精选 -->
        <div class="title-box fr-ac-jsb">
            <div class="title">{{ $t('VIPSelection') }}</div>
            <div class="more-btn fr-ac-jc" @click="changeTab(5)">
                <p>{{ $t('more') }}</p>
                <img src="@/assets/img/right_black.png" alt="">
            </div>
        </div>
        <div v-if="vipDataList && vipDataList.length > 0" class="fc-ac fw">
            <div class="vip-box fr-ac-jsb" v-for="(item, index) in vipDataList" :key="item.id"
                @click="toDetail(item.token, item.view_count, 'vip')">
                <div class="fr-ac">
                    <img class="label-icon mr-20" src="@/assets/img/head.png" alt="">
                    <div class="fc-jsa" style="height: 60px;">
                        <div class="fs-20 fw-b">{{ item.name }}</div>
                        <div>{{ $t('contractCode') }}：{{ item.token }}</div>
                    </div>
                </div>
                <div class="fc-ac">
                    <div class="fs-18 fw-b">${{ Number(item.compare.now_price).toFixed(6) }}</div>
                    <div class="green-bg-box fr-ac-jc" v-if="item.price_diff"
                        :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                        <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                            src="@/assets/img/icon_top.png" alt="">
                        <p style="font-size: 18px;">{{ item.price_diff.toFixed(2) }}%</p>
                    </div>
                </div>
                <div class="gray">{{ $t('pushTime') + "：" + item.add_date }}</div>
            </div>
        </div>
        <div v-else>
            <van-empty :description="$t('noData')" />
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { getData, getVipData } from '@/api/api'
import { getBannerList } from '@/api/login'
import { mapState } from 'vuex'
import { Toast } from "vant";
import { getUserInfo } from '@/api/member'
import Decimal from 'decimal.js';

export default {
    data() {
        return {
            chartInstance: null,
            bannerList: [],// 轮播图
            curNewCurrencyIndex: 0, // 当前新币tab索引
            xbDataList: [], // 新币直播数据
            rmDataList: [], // 热门新币数据
            qlDataList: [], // 潜力新币数据
            qlrmDataList: [], // 潜力热门新币数据
            rdDataList: [], // 交易热点数据
            vipDataList: [], // VIP数据
            interval: null, // 定时器
            level: 0, // 用户等级
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
        //市值
        formattedMarketCap(value) {
            return (value) => {
                return this.$util.formatMarketCap(Number(value) || 0);
            }
        },
    },
    watch: {
        chain(newVal, oldVal) {
            this.getXbData()//新币直播
            this.getQlData()//潜力新币
            this.getRmData()//热门新币
            this.getRdData()//交易热点
            this.getVipData()//vip精选
        },
        '$store.state.login.Token': function () {
            this.getUserInfo()
        },
        '$store.state.login.gameLang': function () {
            this.getBannerList()
        }
        //   $route(route) {
        //     if (route.path.startsWith('/redirect/')) {
        //       return
        //     }
        //     this.getBreadcrumb()
        //   }
    },
    mounted() {
        // this.initChart('chart', 74, 40);
        // this.initChart('new-currency-chart', 74, 40);
        // this.initChart('vip-chart', 32, 16);
        this.getBannerList()
        this.getXbData()//新币直播
        this.getQlData()//潜力新币
        this.getRmData()//热门新币
        this.getRdData()//交易热点
        this.getVipData()//vip精选
        this.createMyInterval() //循环调用
        if (this.$store.state.login.Token) {
            this.getUserInfo()
        }
    },
    beforeDestroy() {
        // 组件销毁前执行的逻辑
        this.clearMyInterval()
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                this.level = res.data.data.userinfo.level
            });
        },
        createMyInterval() {
            this.interval = setInterval(() => {
                this.getXbData()//新币直播
                this.getQlData()//潜力新币
                this.getRmData()//热门新币
                this.getRdData()//交易热点
            }, 5000);  // 每5秒检查一次服务器状态
        },
        clearMyInterval() {
            clearInterval(this.interval);
        },
        // 获取banner
        getBannerList() {
            getBannerList().then((res) => {
                this.bannerList = res.data.data
            });
        },
        changeNewCurrencyTab(index) {
            this.curNewCurrencyIndex = index
            if (this.curNewCurrencyIndex === 0) {
                this.qlrmDataList = this.qlDataList
            } else {
                this.qlrmDataList = this.rmDataList
            }
        },
        //新币直播
        getXbData() {
            let chain = ''
            if (this.$store.state.login.chain == 'ETH') {
                chain = 'ethxb'
            } else {
                chain = 'solxb'
            }
            getData(chain).then((res) => {
                this.xbDataList = res.data.data.splice(0, 4)
            });
        },
        //潜力新币
        getQlData() {
            let chain = ''
            if (this.$store.state.login.chain == 'ETH') {
                chain = 'ethql'
            } else {
                chain = 'solql'
            }
            getData(chain).then((res) => {
                this.qlDataList = res.data.data.splice(0, 6)
                if (this.curNewCurrencyIndex == 0) {
                    this.qlrmDataList = this.qlDataList
                }
            });
        },
        //热门新币
        getRmData() {
            let chain = ''
            if (this.$store.state.login.chain == 'ETH') {
                chain = 'ethrm'
            } else {
                chain = 'solrm'
            }
            getData(chain).then((res) => {
                this.rmDataList = res.data.data.rank.splice(0, 6)
                if (this.curNewCurrencyIndex == 1) {
                    this.qlrmDataList = this.rmDataList
                }
            });
        },
        //交易热点
        getRdData() {
            let chain = ''
            if (this.$store.state.login.chain == 'ETH') {
                chain = 'ethrd'
            } else {
                chain = 'solrd'
            }
            getData(chain).then((res) => {
                this.rdDataList = res.data.data.splice(0, 6)
            })
        },
        //vip精选
        getVipData() {
            let chain = ''
            if (this.$store.state.login.chain == 'ETH') {
                chain = 'ethvip'
            } else {
                chain = 'solvip'
            }
            getVipData(chain, 'vip', 1, 4, '').then((res) => {
                this.vipDataList = res.data.data.list
                this.vipDataList.forEach(item => {
                    item.add_date = item.add_date.slice(0, 10)
                    let now_price = new Decimal(item.compare.now_price);
                    let last_price = new Decimal(item.compare.last_price);
                    if (item.compare) {
                        this.$set(item, 'price_diff', now_price.minus(last_price).dividedBy(last_price))
                    }
                })
            })
        },
        // 跳转详情页
        toDetail(address, view_count, type) {
            if (type == 'vip' && this.level == 0) {
                Toast(this.$t('openVipTip'))
                return
            }
            this.$router.push({
                path: '/pc/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
        changeTab(index) {
            if (index === 2 && this.curNewCurrencyIndex === 1) {
                index = 3
            }
            let path = ''
            let type = 0
            switch (index) {
                case 1:
                    path = 'pcNewLive'
                    type = 1
                    break;
                case 2:
                    path = 'pcNewLive'
                    type = 2
                    break;
                case 3:
                    path = 'pcNewLive'
                    type = 3
                    break;
                case 4:
                    path = 'pcNewLive'
                    type = 4
                    break;
                case 5:
                    path = 'vipSelection'
                    break;
                default:
                    break;
            }
            localStorage.setItem('tabIndex', index)
            if (path == 'vipSelection') {
                this.$router.push({
                    path: '/pc/' + path,
                })
            } else {
                this.$router.push({
                    path: '/pc/' + path,
                    query: {
                        type
                    }
                })
            }

            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        // initChart(id, width, height) {
        //     // 初始化图表实例
        //     this.chartInstance = echarts.init(document.getElementById(id), null, {
        //         width,
        //         height
        //     });
        //     // 配置图表的选项
        //     const option = {
        //         title: {
        //             text: ''
        //         },
        //         tooltip: {},
        //         legend: {
        //             data: ['销量']
        //         },
        //         xAxis: {
        //             data: []
        //         },
        //         yAxis: {},
        //         series: [{
        //             // name: '销量',
        //             type: 'line',
        //             smooth: true,
        //             data: [5, 20, 10, 10, 10, 20, 10]
        //         }],
        //         dataZoom: [
        //             {
        //                 type: 'slider',  // 可以使用滑动条进行缩放
        //                 show: true,
        //                 start: 0,  // 初始显示的起始比例
        //                 end: 100   // 初始显示的结束比例
        //             }
        //         ]
        //     };

        //     // 使用设置的配置项初始化图表
        //     this.chartInstance.setOption(option);

        //     // 在组件销毁时清理图表实例
        //     window.addEventListener('resize', this.chartInstance.resize);
        //     this.$once('hook:beforeDestroy', () => {
        //         this.chartInstance.dispose();
        //     });
        // },
    }
}
</script>

<style lang="less" scoped>
//轮播图居中
/deep/.el-carousel__item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//轮播图指示器样式
/deep/.el-carousel__button {
    background-color: rgb(95, 98, 107);
    opacity: 1;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

//轮播图指示器活跃样式
/deep/.is-active .el-carousel__button {
    background-color: rgb(112, 237, 36);
    width: 28px;
    border-radius: 20px;
}

.swiper-img {
    width: 390px;
    height: 274px;
    border-radius: 20px;
}


.hot-list-box {
    width: 306px;
    height: 443px;
    background: #F7F7F7;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 14px;
    position: relative;
    cursor: pointer;

    .white-bg {
        width: 306px;
        height: 382px;
        background: #FFFFFF;
        border-radius: 0px 14px 14px 14px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .content-box {
        width: 306px;
        height: 234px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 24px 21px;

        .hot-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 1;
            // margin-top: 4px;
        }

        .hot-center-box {
            margin-top: 40px;
            padding-bottom: 30px;
            border-bottom: 1px dashed #E3E3E3;
        }

        .hot-chart {
            width: 74px;
            height: 40px;
        }
    }
}

.title-box {
    padding: 70px 16px 23px;

    .title {
        font-weight: bold;
        font-size: 24px;
        color: #000000;
        position: relative;
    }

    .title::after {
        width: 95px;
        height: 6px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
    }

    .more-btn {
        width: 60px;
        height: 36px;
        line-height: 36px;
        background: #70ED24;
        border-radius: 5px 5px 13px 5px;
        position: relative;
        cursor: pointer;

        p {
            font-weight: 500;
            line-height: 1;
            font-size: 15px;
        }

        img {
            width: 16px;
            height: 18px;
            position: absolute;
            right: -8px;
        }
    }
}

/deep/ .van-swipe__indicators {
    bottom: 0;

    .van-swipe__indicator {
        background-color: rgb(95, 98, 107);
        opacity: 1;
        width: 8px;
        height: 8px;
    }

    .van-swipe__indicator--active {
        background-color: rgb(112, 237, 36);
        width: 16px;
        height: 8px;
        border-radius: 5px;
    }
}

.new-currency-tab-wrap {
    width: 426px;
    height: 68px;
    background: #F7F7F7;
    border-radius: 12px;
    margin: 110px auto 70px;
    position: relative;
    cursor: pointer;

    .new-currency-tab-item {
        transition: all 0.5s;
        width: 206px;
        height: 54px;
        border-radius: 8px;
        color: #A2A2A2;
        font-weight: bold;
        font-size: 26px;
        position: relative;
        z-index: 0;

        &.active {
            background: #FFFFFF;
            color: #000000;
        }

        &.active::after {
            width: 98px;
            height: 5px;
            content: '';
            background: #70ED24;
            border-radius: 5px;
            position: absolute;
            z-index: -1;
            bottom: 14px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.new-currency-content-top {
    width: 220px;
    height: 58px;
    background: #2E2B2B;
    border-radius: 14px;
    margin: 0 auto;
    cursor: pointer;

    p {
        font-size: 16px;
        color: #70ED24;
        margin-right: 6px;
    }

    img {
        width: 24px;
        height: 24px;
    }
}

.new-currency-bg {
    width: 408px;
    height: 242px;
    background: #2E2B2B;
    border-radius: 16px;
    position: relative;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
    margin-bottom: 30px;
    cursor: pointer;
}

.new-currency-content-center {
    width: 408px;
    height: 233px;
    border-radius: 14px;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .info-top {
        padding-bottom: 15px;
        background-color: #F7F7F7;
        border-radius: 14px 14px 0px 0px;
        padding: 22px 20px 30px;

        .hot-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 1;
            margin-bottom: 12px;
        }

        .hot-chart {
            width: 74px;
            height: 40px;
        }

    }
}

.vip-box {
    width: 100%;
    height: 80px;
    background: #FFFCFC;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 7px;
    margin: 0 auto 30px;
    padding: 16px 20px;
    cursor: pointer;

    .vip-title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 6px;
    }

    .vip-chart {
        width: 32px;
        height: 18px;
        margin-left: 6px;
        margin-top: 6px;
    }
}

.search-wrap {
    width: 355px;
    // height: 314px;
    background: #FFFFFF;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 22px 19px 10px;

    .search-tip {
        font-weight: bold;
        font-size: 19px;
        margin-top: 68px;
        margin-bottom: 17px;
    }

    .search-list {
        height: 480px;
        padding-top: 10px;

        .search-item {
            width: 100%;
            height: 34px;
            background: #F2F3F7;
            border-radius: 5px;
            padding: 6px 8px;
            margin-bottom: 13px;
        }

        img {
            width: 21px;
            height: 21px;
            margin-right: 6px;
        }
    }
}
</style>