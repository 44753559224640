import req from "@/utils/request";
import des from "@/utils/des";
import store from "@/store";

let request = req._axios;
let request1 = req._axios1;
let request2 = req._axios2;
// 获取详情数据
export const getDetail = (address) => {
  return request1({
    url: "/latest/dex/tokens/" + address,
    method: "get",
    headers: {},
    data: {},
  });
};

// 获取列表数据
export const getData = (type) => {
  return request2({
    url: "/api/index/get_data",
    method: "post",
    headers: {},
    data: {
      name: type,
    },
  });
};

// 获取列表数据
export const getVipData = (type, cate, page, limit, keyword) => {
  return request({
    url: `/api/btc/index?type=${type}&cate=${cate}&page=${page}&limit=${limit}&keyword=${
      keyword || ""
    }`,
    method: "get",
    headers: {},
    data: {},
  });
};
