import { loginAPI } from "@/api/login";
export default {
  data() {
    return {};
  },
  mounted() {
    if (this.$store.state.login.account1 && !this.$store.state.login.Token) {
      loginAPI(this.$store.state.login.account1).then((res) => {
        console.log("minin登录token", res.data.data.userinfo.token);
        this.$store.commit("login/changeToken", res.data.data.userinfo.token);
      });
      return;
    }
  },
  methods: {},
};
