import axios from "axios";
import store from "@/store";

const _axios = axios.create({
  baseURL: process.env.VUE_APP_URL,
});
_axios.interceptors.request.use(
  (config) => {
    config.headers.Token = store.state.login.Token;
    config.headers.lang = store.state.login.gameLang == "CN" ? "zh-cn" : "en";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
_axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const _axios1 = axios.create({
  baseURL: "https://api.dexscreener.com",
});
_axios1.interceptors.request.use(
  (config) => {
    config.headers.Token = store.state.login.Token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
_axios1.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const _axios2 = axios.create({
  baseURL: "http://hottoken.top",
});
_axios2.interceptors.request.use(
  (config) => {
    config.headers.Token = store.state.login.Token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
_axios2.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default { _axios, _axios1, _axios2 };
