export default {
  getStatusBarHeight() {
    // iOS 特殊处理：判断设备类型
    const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isIos) {
      // iPhone X/XS 及以上设备有特殊的状态栏高度（通常是 44px）
      if (window.innerHeight === 812 || window.innerHeight === 896) {
        return 44; // iPhone X/XS, iPhone 11 Pro 等
      }
      return 20; // 一般 iOS 设备的状态栏高度为 20px
    }
    // 其他设备的状态栏高度
    return 24; // 一些安卓设备
  },
  // 格式化市值的方法
  formatMarketCap(value) {
    if (value >= 1e12) {
      // 万亿
      return (value / 1e12).toFixed(2) + "T"; // T: Trillion
    } else if (value >= 1e9) {
      // 十亿
      return (value / 1e9).toFixed(2) + "B"; // B: Billion
    } else if (value >= 1e6) {
      // 百万
      return (value / 1e6).toFixed(2) + "M"; // M: Million
    } else if (value >= 1e3) {
      // 千
      return (value / 1e3).toFixed(2) + "K"; // K: Thousand
    } else {
      return value.toFixed(2); // 小于千时直接返回原始值
    }
  },
  //获取日期
  formattedDate(time) {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  },
};
