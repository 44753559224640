<template>
  <div class="home" v-if="checkIsMobile">
    <topInfo ref="topInfo"></topInfo>
    <div class="pos-r">
      <div class="vague" :style="'top:' + topComponentHeight + 'px;'" @click.stop></div>
      <van-tabs :active="curIndex" color="#70ED24" line-height="4" line-width="32" :swipeable="true" sticky
        :ellipsis="false" :offset-top="topComponentHeight - 5" title-active-color="#000000"
        title-inactive-color="#5F626B" @change="changeTab">
        <van-tab :title="$t('home')">
          <home @changeTab="changeTab" :level="level" ref="home"></home>
        </van-tab>
        <van-tab :title="$t('newLive')">
          <newLive type="1" ref="xbTab"></newLive>
        </van-tab>
        <van-tab :title="$t('potentialCurrency')">
          <newLive type="2" ref="qlTab"></newLive>
        </van-tab>
        <van-tab :title="$t('popularCoins')">
          <newLive type="3" ref="rmTab"></newLive>
        </van-tab>
        <van-tab :title="$t('tradingHotspots')">
          <newLive type="4" ref="rdTab"></newLive>
        </van-tab>
        <!-- <van-tab :title="$t('VIPSelection')">
          <vipSelection :level="level"></vipSelection>
        </van-tab> -->
      </van-tabs>
    </div>
    <!-- 底部广告 -->
    <div class="fc-ac mt-30">
      <img class="logo" src="@/assets/img/logo.png" alt="">
      <div class="tip">- {{ $t('riskTip') }} -</div>
      <div class="fr-ac">
        <img class="code" src="@/assets/img/code.png" alt="">
        <img class="advertisement" src="@/assets/img/advertisement.png" alt="">
      </div>
    </div>
  </div>
  <div v-else class="fc-ac">
    <topInfoPc></topInfoPc>
    <div class="common">
      <router-view :key="$route.fullPath"></router-view>
      <!-- 底部广告 -->
      <bottomAd></bottomAd>
    </div>
  </div>
</template>

<script>
import util from '@/utils/util.js'
import topInfo from "@/components/topInfo";
import home from "@/components/home";
import newLive from "@/components/newLive";
import potentialCurrency from "@/components/potentialCurrency";
import vipSelection from "@/components/vipSelection";
import store from "@/store";
import { getUserInfo } from '@/api/member'
import { mapGetters } from 'vuex'
import { loginAPI } from '@/api/login'
import i18n from '@/lang'
import web3JS from '@/utils/web3'
import des from '@/utils/des'
import { Toast } from "vant";
import topInfoPc from '@/components/topInfoPc'
import pcHome from '@/components/pcHome'
import bottomAd from '@/components/bottomAd'
export default {
  components: {
    topInfo,
    home,
    newLive,
    potentialCurrency,
    vipSelection,
    topInfoPc,
    pcHome,
    bottomAd
  },
  data() {
    return {
      statusBarHeight: '',
      curIndex: 0,
      level: 0,
      topComponentHeight: 0
    }
  },
  computed: {
    ...mapGetters([
      'checkIsMobile',
    ])
  },
  watch: {
    '$store.state.login.Token': function () {
      this.getUserInfo()
    }
  },
  mounted() {
    this.statusBarHeight = util.getStatusBarHeight()
    this.curIndex = localStorage.getItem('tabIndex') || 0
    if (this.$store.state.login.Token && window.innerWidth <= 768) {
      this.getUserInfo()
    }
    this.$nextTick(() => {
      if (this.checkIsMobile) {
        this.topComponentHeight = this.$refs.topInfo.$el.offsetHeight
      }
    })
  },
  methods: {
    changeTab(index) {
      this.curIndex = index
      switch (index) {
        case 0:
          this.$refs.home?.createMyInterval()
          this.$refs.xbTab?.clearMyInterval()
          this.$refs.qlTab?.clearMyInterval()
          this.$refs.rmTab?.clearMyInterval()
          this.$refs.rdTab?.clearMyInterval()
          break;
        case 1:
          this.$refs.home?.clearMyInterval()
          this.$refs.xbTab?.createMyInterval()
          this.$refs.qlTab?.clearMyInterval()
          this.$refs.rmTab?.clearMyInterval()
          this.$refs.rdTab?.clearMyInterval()
          break;
        case 2:
          this.$refs.home?.clearMyInterval()
          this.$refs.xbTab?.clearMyInterval()
          this.$refs.qlTab?.createMyInterval()
          this.$refs.rmTab?.clearMyInterval()
          this.$refs.rdTab?.clearMyInterval()
          break;
        case 3:
          this.$refs.home?.clearMyInterval()
          this.$refs.xbTab?.clearMyInterval()
          this.$refs.qlTab?.clearMyInterval()
          this.$refs.rmTab?.createMyInterval()
          this.$refs.rdTab?.clearMyInterval()
          break;
        case 4:
          this.$refs.home?.clearMyInterval()
          this.$refs.xbTab?.clearMyInterval()
          this.$refs.qlTab?.clearMyInterval()
          this.$refs.rmTab?.clearMyInterval()
          this.$refs.rdTab?.createMyInterval()
          break;
        case 5:
          this.$refs.home?.clearMyInterval()
          this.$refs.xbTab?.clearMyInterval()
          this.$refs.qlTab?.clearMyInterval()
          this.$refs.rmTab?.clearMyInterval()
          this.$refs.rdTab?.clearMyInterval()
          break;
        default:
          break;
      }
      if (index == 5) {
        //vip
        this.$router.push('/mobile/vipIndex')
        localStorage.setItem('tabbarIndex', index)
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        localStorage.setItem('tabIndex', index)
      }
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.level = res.data.data.userinfo.level
      });
    },
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 375px;
  padding-bottom: 55px;

  /deep/.van-tab--active {
    font-weight: bold;
  }

  /deep/.van-tab {
    font-size: 16px;
  }

  /deep/ .van-tabs__line {
    bottom: 0.6rem;
  }

  .vague {
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    height: 40px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1));
    z-index: 100;
    pointer-events: none;
  }

  .logo {
    width: 151px;
    height: 43px;
  }

  .tip {
    font-weight: bold;
    font-size: 12px;
    margin: 27px 50px 18px;
    text-align: center;
  }

  .code {
    width: 47px;
    height: 47px;
    margin-right: 9px;
  }

  .advertisement {
    width: 191px;
    height: 40px;
  }
}

//PC
.top-fixed-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 0 98px;

  .logo {
    width: 221px;
    height: 62px;
  }
}
</style>
