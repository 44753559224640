<template>
    <div class="content-box fc-ac">
        <div class="my-collect-title">{{ $t('helpCenter') }}</div>
        <div class="scroll-wrap">
            <div class="table-wrap scroll-container">
                <div class="table-body" v-if="dataList && dataList.length > 0">
                    <div class="item-box fr-ac" v-for="(item, index) in dataList" :key="index"
                        @click="$router.push('/pc/pcMemberIndex/pcHelpDetail?id=' + item.id)">
                        <div class="green-box"></div>
                        <div class="fs-14">{{ item.name }}</div>
                    </div>
                </div>
                <div class="table-body" v-else>
                    <van-empty style="margin-top: 100px;" :description="$t('noData')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { getHelpList } from '@/api/member'
export default {
    components: {

    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {

    },
    watch: {
        '$store.state.login.gameLang': function () {
            this.getHelpList()
        }
    },
    mounted() {
        this.getHelpList()
    },
    methods: {
        getHelpList() {
            let data = {
                page: 1,
                limit: 1000,
                type: "help"
            }
            getHelpList(data).then(res => {
                this.dataList = res.data.data.list
            })
        }
    }
}
</script>

<style lang="less" scoped>
.content-box {
    width: 860px;
    height: 907px;
    background: #2E2E2E;
    border-radius: 10px;
    position: relative;

    .my-collect-title {
        font-weight: 800;
        font-size: 18px;
        color: #70ED24;
        position: absolute;
        top: 21px;
        left: 36px;
    }

    .scroll-wrap {
        width: 840px;
        height: 832px;
        background: #FFFFFF;
        padding: 18px 0;
        margin-top: 65px;
        border-radius: 8px;
    }

    .table-wrap {
        width: 825px;
        height: 800px;
        padding: 0 22px;
        overflow: scroll;
        overflow-x: hidden;
    }
}

.item-box {
    height: 50px;
    padding: 0 16px;
    border-bottom: 1px dotted #999999;
    cursor: pointer;
}

.green-box {
    width: 19px;
    height: 19px;
    background: #70ED24;
    margin-right: 15px;
    flex-shrink: 0;
}
</style>