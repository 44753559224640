export default {
  namespaced: true,
  state: {
    account1: "", //钱包地址
    Token: localStorage.getItem("token") || "",
    userCode: "",
    userId: "",
    gameLang: "CN", // 语言
    chain: "SOL", //链 ETH SOL
    code: "", // 邀请码
  },
  getters: {
    getToken(state) {
      return state.token;
    },
  },
  mutations: {
    changeAccount1(state, res) {
      state.account1 = res;
    },
    clearAccount1(state) {
      state.account1 = null;
    },
    changeToken(state, res) {
      state.Token = res;
      localStorage.setItem("token", res); // 同时存储到 localStorage
    },
    clearToken(state) {
      state.Token = null;
      localStorage.removeItem("token");
    },
    changeUserCode(state, res) {
      state.userCode = res;
    },
    changeUserId(state, res) {
      state.userId = res;
    },
    changeGameLang(state, res) {
      state.gameLang = res;
    },
    changeChain(state, res) {
      state.chain = res;
    },
    changeCode(state, res) {
      state.code = res;
    },
    // 调用mutations   this.$store.commit('setAaaName',实参值)
  },
};
