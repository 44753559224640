import Vue from "vue";
import Vuex from "vuex";
import persistedstate from "vuex-persistedstate";
import login from "./login";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {
    //判断pc端还是移动端
    checkIsMobile: (state) => {
      // const userAgent = navigator.userAgent;
      // return /iPhone|iPad|iPod|Android/i.test(userAgent);
      return window.innerWidth <= 768; //如果屏幕宽度小于768，则认为是移动端
    },
  },
  mutations: {},
  actions: {},
  modules: {
    login,
  },
  plugins: [
    persistedstate({
      paths: ["login", "login.account1", "login.Token"],
      // 需要持久化的模块名:针对 整个模块持久化
      // 需要持久化的模块名.模块内的字段名：模块内某个数据持久化
    }),
  ],
});
