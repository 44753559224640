export default {
  lang: "English",
  search: "Search",
  searchPlaceholder: "Please enter the complete contract address",
  wallet: "Wallet",
  walletLogin: "Wallet Login",
  login: "Login",
  logined: "Logged in",
  home: "Home",
  consulting: "Consulting",
  memberCenter: "Member",
  memberCenter2: "Member Center",
  newLive: "New Listing",
  potentialCurrency: "Potential",
  popularCoins: "Popular",
  tradingHotspots: "Most Buy",
  VIPSelection: "VIP Selection",
  profitStatistics: "Profit statistics",
  more: "More",
  checkMore: "READ MORE",
  marketCap: "MCap",
  mobility: "Liq",
  txns: "Txns",
  holders: "Holders",
  volume: "VOLUME",
  buys: "BUYS",
  sells: "SELLS",
  txns24: "Txns(24h)",
  volume24: "Volume(24h)",
  dataInformation: "Data Information",
  collectionSuccessful: "Collection successful",
  shareSuccessful: "The sharing link has been copied to the clipboard",
  contractCode: "token",
  membershipDescription: "Membership Description",
  membershipExpiration: "Membership Expiration",
  upgradeMembership: "Upgrade Membership",
  accountBalance: "Account balance",
  withdrawable: "withdrawable",
  withdrawal: "Withdrawal",
  accumulatedIncome: "Accumulated income",
  pendingReview: "Pending review",
  myTeam: "My team",
  inviteFriends: "Invite friends",
  inviteToEarnProfits: "Invite to earn profits",
  myCollection: "My collection",
  helpCenter: "Help center",
  openMembership: "Open membership",
  openTip: "Open platform membership and enjoy good content as a member",
  membershipBenefits:
    "Enjoy high-quality columns and exclusive information for members",
  highQualityColumn: "High quality column",
  newCoinRecommendation: "New coin recommendation",
  exclusiveCustomerService: "Customer service",
  greatValueDiscounts: "Great value discounts",
  memberBenefits: "Membership Benefits",
  authorizationContract: "Authorization",
  pay: "Pay",
  authorizationSuccessful: "Authorization successful",
  authorizationFailed: "Authorization failed",
  installMetaMask: "Please install the MetaMask wallet plugin",
  transactionSent: "Transaction sent",
  transactionConfirmed: "Transaction confirmed",
  transactionFailed: "Transaction failed",
  wantWithdrawal: "Withdrawal",
  withdrawalInstructions: "Withdrawal instructions",
  withdrawTip: "Please enter the amount you want to withdraw",
  minimum: "Minimum",
  remaining: "Remaining",
  WithdrawToCurrentAccount: "Withdraw to current account",
  serviceCharge: "Service Charge",
  notice: "Notice",
  inputWwithdrawalAmount: "Please enter the withdrawal amount",
  withdrawalCannotLess: "The withdrawal amount cannot be less than",
  withdrawalSuccessful: "Withdrawal successful",
  accumulatedMembershipDevelopment: "Accumulated membership development",
  accumulatedIncome: "Accumulated income",
  user: "User",
  profit: "Profit",
  noTeam: "No team",
  name: "Name",
  price: "Price",
  cancel: "Cancel",
  noCollection: "No collection",
  time: "Time",
  riskTip:
    "Risk in the cryptocurrency industry should be approached with caution",
  noData: "No Data",
  copySuccess: "Cope Success",
  WalletPlugInNotInstalled: "Wallet plug-in not installed",
  openVipTip: "Please activate VIP first",
  loginWallet: "Please log in to the wallet first",
  loading: "Loading...",
  noMore: "No more",
  views: "Views",
  backList: "Back",
  pushTime: "Push Time",
  insufficientBalance: "Insufficient Balance",
  failedObtainBalance: "Failed to obtain balance",
};
