import req from "@/utils/request";
import des from "@/utils/des";
import store from "@/store";
let key = "1663335c";

let request = req._axios;
// 登录
export const loginAPI = (address) => {
  return request({
    url: "/api/user/reg2login",
    method: "post",
    headers: {
      lang: store.state.login.gameLang == "CN" ? "zh-cn" : "en",
    },
    data: {
      username: address, //用户名
      recommend_code: store.state.login.code, //邀请码
    },
  });
};

// 注册
export const registerAPI = (address, id, code, invitation) => {
  return request({
    url: "/api/user/register",
    method: "post",
    headers: {
      lang: store.state.login.gameLang == "CN" ? "zh-cn" : "en",
    },
    data: {
      wallet_address: address,
      user_id: id,
      user_code: code,
      invitation: invitation,
    },
  });
};

// 轮播图
export const getBannerList = () => {
  return request({
    url: "/api/banner/list",
    method: "get",
    headers: {
      lang: store.state.login.gameLang == "CN" ? "zh-cn" : "en",
    },
    data: {},
  });
};

// 公告
// export const NoticeIndex = (page, size) => {
//   return request({
//     url: "/Notice/index",
//     method: "post",
//     headers: {
//       Sessionid: 1,
//       Loginuserid: des.decryptDes(store.state.login.userId, key),
//       Usercode: des.decryptDes(store.state.login.userCode, key),
//     },
//     data: {
//       page,
//       size,
//     },
//   });
// };
