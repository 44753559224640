<template>
    <div class="pb-30">
        <div class="title">{{ $t('VIPSelection') }}</div>
        <van-skeleton :row="20" :loading="loading">
            <div v-if="vipDataList && vipDataList.length > 0">
                <div class="vip-box" v-for="(item, index) in vipDataList" :key="index" @click="toDetail(item.token)">
                    <van-col span="4">
                        <img class="label-icon" :src="item.icon" alt="">
                    </van-col>
                    <van-col span="20" class="fc-jsb" style="height: 70px;">
                        <div class="fs-16">{{ item.name }}</div>
                        <div>{{ item.token }}</div>
                        <div class="fr-ac">
                            <div class="mr-10">${{ Number(item.compare.now_price).toFixed(6) }}</div>
                            <div class="green-bg-box fr-ac-jc mr-10" style="margin-top: 0;" v-if="item.price_diff"
                                :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                    src="@/assets/img/icon_top.png" alt="">
                                <p>{{ item.price_diff.toFixed(2) }}%</p>
                            </div>
                            <div class="gray">{{ $t('pushTime') + "：" + item.add_date }}</div>
                        </div>
                    </van-col>
                </div>
            </div>
            <div class="table-body" v-else>
                <van-empty :description="$t('noData')" />
            </div>
        </van-skeleton>
    </div>
</template>

<script>
import { getVipData } from '@/api/api'
import { mapState } from 'vuex'
import { Toast } from "vant";
import { getUserInfo } from '@/api/member'
import Decimal from 'decimal.js';
export default {
    data() {
        return {
            vipDataList: [],
            loading: true,
            level: 0
        }
    },
    watch: {
        chain(newVal, oldVal) {
            this.getDataList()
        },
        '$store.state.login.Token': function () {
            this.getUserInfo()
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
    },
    mounted() {
        this.getDataList()
        if (this.$store.state.login.Token) {
            this.getUserInfo()
        }
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                this.level = res.data.data.userinfo.level
            });
        },
        getDataList() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethvip'
            } else {
                chain = 'solvip'
            }
            getVipData(chain, 'vip', 1, 100, '').then((res) => {
                this.vipDataList = res.data.data.list
                this.vipDataList.forEach(item => {
                    item.add_date = item.add_date.slice(0, 10)
                    let now_price = new Decimal(item.compare.now_price);
                    let last_price = new Decimal(item.compare.last_price);
                    if (item.compare) {
                        this.$set(item, 'price_diff', now_price.minus(last_price).dividedBy(last_price))
                    }
                })
                this.loading = false
            })
        },
        // 跳转详情页
        toDetail(address) {
            if (this.level == 0) {
                Toast(this.$t('openVipTip'))
                return
            }
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.title {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin: 30px 0 20px 30px;
}

.table-body {
    width: 345px;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        height: 50px;
    }

    .icon-img {
        width: 26px;
        height: 26px;
        margin-right: 7px;
    }

    .arrow-img {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }

    .status-img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }

    .bgc {
        background: #F7F8FF;
    }
}

.vip-box {
    width: 345px;
    height: 90px;
    background: #FFFCFC;
    background-size: cover;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 7px;
    margin: 0 auto 15px;
    padding: 16px 10px;
    font-size: 12px;

    .vip-title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 6px;
    }

    .vip-chart {
        width: 32px;
        height: 18px;
        margin-left: 6px;
        margin-top: 6px;
    }
}
</style>