<template>
  <div class="fr p-10">
    <div class="fc">
      <div class="wallet-box">
        <div class="fr-ac-jc explain-box" @click="isShowPop = true">
          <van-icon name="question-o" size="16px" color="#70ED24" />
          <div style="line-height: 1;margin-left: 5px">{{ $t('membershipDescription') }}</div>
        </div>
        <div class="info-box fc">
          <div class="fr-ac mb-20">
            <img :src="userInfo.avatar || require('@/assets/img/member-head.png')" alt="">
            <div class="fc" v-if="$store.state.login.Token">
              <div class="fr mb-10">
                <div class="fs-18 fw-b" v-if="userInfo.nickname">{{ userInfo.nickname }}</div>
                <div class="member-time" v-if="userInfo.levelName">{{ userInfo.levelName }}</div>
              </div>
              <div class="fr">
                <div class="gray mr-10" v-if="userInfo.member_date">{{ $t('membershipExpiration') }}:{{
          userInfo.member_date }}</div>
                <div class="green fw-b pointer" @click="openVipPop()">{{ $t('upgradeMembership') }}</div>
              </div>
            </div>
            <div class="fc fs-20 pointer" v-else @click="login()">
              {{ $t('login') }}
            </div>
          </div>
          <div class="horizontal-line"></div>
          <div class="fr-ac-jsb">
            <div class="fc">
              <div class="fs-12 mb-20"><span class="fs-16">{{ $t('accountBalance') }}</span>（{{
          $t('withdrawable')
        }}）</div>
              <div class="fw-b" style="font-size: 38px;">{{ userInfo.money || '0.000' }} <span class="fs-16"
                  style="margin-left: 6px;">USTD</span>
              </div>
            </div>
            <div class="withdrawal-btn" @click="openWithdrawalPop()">{{
          $t('withdrawal') }}</div>
            <!-- <div class="withdrawal-btn" @click="$router.push('/withdrawal')">我要提现</div> -->
          </div>
        </div>
        <div class="data-box fc-jsa">
          <div class="fc pos-r">
            <div class="data-title">{{ $t('accumulatedIncome') }}</div>
            <div class="fs-28 gray fw-b">{{ userInfo.total_profit || 0 }} <span class="danwei">USTD</span> </div>
          </div>
          <div class="fc pos-r">
            <div class="data-title">{{ $t('pendingReview') }}</div>
            <div class="fs-28 gray fw-b">{{ userInfo.total_withdraw || 0 }} <span class="danwei">USTD</span> </div>
          </div>
        </div>
      </div>

      <div class="function-box fc-ac">
        <div class="member-center">{{ $t('memberCenter2') }}</div>
        <div class="function-list">
          <div class="function-item fr-ac-jsb" @click="toPage('/pc/pcMemberIndex')">
            <div class="fr-ac">
              <img src="@/assets/img/function1.png" alt="">
              <div class="fs-16">{{ $t('myTeam') }}</div>
            </div>
            <div class="fr-ac">
              <van-icon name="arrow" size="16px" color="#CCCCCC" />
            </div>
          </div>
          <div class="function-item fr-ac-jsb" @click="toInvite">
            <div class="fr-ac">
              <img src="@/assets/img/function2.png" alt="">
              <div class="fs-16">{{ $t('inviteFriends') }}</div>
            </div>
            <div class="fr-ac">
              <div class="tip">{{ $t('inviteToEarnProfits') }}</div>
              <van-icon name="arrow" size="16px" color="#CCCCCC" />
            </div>
          </div>
          <div class="function-item fr-ac-jsb" @click="toPage('/pc/pcMemberIndex/pcMyCollect')">
            <div class="fr-ac">
              <img src="@/assets/img/function3.png" alt="">
              <div class="fs-16">{{ $t('myCollection') }}</div>
            </div>
            <div class="fr-ac">
              <van-icon name="arrow" size="16px" color="#CCCCCC" />
            </div>
          </div>
          <div class="function-item fr-ac-jsb" @click="toPage('/pc/pcMemberIndex/pcHelpList')">
            <div class="fr-ac">
              <img src="@/assets/img/function5.png" alt="">
              <div class="fs-16">{{ $t('helpCenter') }}</div>
            </div>
            <div class="fr-ac">
              <van-icon name="arrow" size="16px" color="#CCCCCC" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>

    <!-- 会员说明弹窗 -->
    <van-popup v-model="isShowPop" round @close="closePop()" z-index="100">
      <div class="">
        <div class="fr-ac-jsb pop-top">
          <div class="fs-16 fw-b green">{{ $t('membershipDescription') }}</div>
          <van-icon name="cross" color="#70ED24" size="20" @click="closePop()" />
        </div>
        <div class="pop-center">
          <div v-if="membershipDescription" v-html="membershipDescription"></div>
        </div>
      </div>
    </van-popup>

    <!-- 提现弹窗 -->
    <el-dialog width="580px" :title="$t('withdrawal')" :visible.sync="showWithdrawalPop">
      <div class="withdrawal-pop">
        <div class="pop-info-box">
          {{ $t('accountBalance') + '（' + $t('withdrawable') + '）' }}<span class="fs-28 ml-20 mr-10">{{ userInfo.money
          || 0
            }}</span>USTD
        </div>
        <div class="wallet-pop-box">
          <div class="input-box">
            <p>{{ $t('withdrawTip') }}<span v-if="minMoney">({{ $t('minimum') + minMoney }})</span></p>
            <van-cell-group>
              <van-field type="number" v-model="inputMoney"
                :placeholder="$t('remaining') + ' ' + userInfo.money + 'USTD ' + $t('withdrawable')" />
            </van-cell-group>
            <div class="fc-ac">
              <div class="withdrawal-btn" @click="withdrawal()">{{ $t('WithdrawToCurrentAccount') }}({{
          $t('serviceCharge') + ' ' + fee }}%)</div>
            </div>
          </div>
          <div style="padding: 10px 30px 30px;">
            <p class="fs-18 fw-b black">{{ $t('notice') }}：</p>
            <div class="tip-box">
              <div v-if="withdrawalInstructions" v-html="withdrawalInstructions"></div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 开通vip弹窗 -->
    <el-dialog width="580px" :title="$t('openMembership')" :visible.sync="showOpenVipPop">
      <div class="bg-top">
        <div class="open-member">OPEN MEMBER</div>
        <div class="equity-box">
          <div class="function-tip">{{ $t('membershipBenefits') }}</div>
          <div class="pop-content-box">
            <div class="function-list fr-ac-jsb">
              <div class="fc-ac" style="width: 25%;" v-for="(item, index) in functionList" :key="index">
                <img :src="item.img" alt="">
                <div class="ta-c">{{ item.title }}</div>
              </div>
            </div>
            <div class="fr-ac member-level-list">
              <div v-for="(item, index) in memberLevelList" :key="index">
                <div class="member-type-box fc-ac-jsb" :class="selectMemberTypeIndex === index ? 'active' : ''"
                  @click="selectMemberType(index)">
                  <div class="fs-16">{{ item.name }}</div>
                  <div class="fs-14">u<span class="fs-22 fw-b">{{ item.quantity }}</span></div>
                </div>
              </div>
            </div>
            <div class="fs-16 fw-b mt-40 mb-20" v-if="paymentRule">{{ $t('memberBenefits') }}</div>
            <div class="light-gray" style="line-height: 1.6;">
              <div v-if="paymentRule" v-html="paymentRule"></div>
            </div>
            <div class="fr-ac-jc btn-box">
              <el-button type="default" @click="authorize">{{ $t('authorizationContract') }}</el-button>
              <el-button type="default" :disabled="!isPay" @click="payment">{{ $t('pay') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo, getRules, getInviteAddress, getWithdrawalHandlingFee, withdrawApply, uploadFile, getMemberUserLevel, getPayOrder, getRechargeConfig } from '@/api/member'
import web3JS from '@/utils/web3'
import { Toast, Dialog } from "vant";
import { mapGetters } from 'vuex'
import Web3 from '@/utils/web3.min'
import getContract from '@/utils/contract'
import { loginAPI } from "@/api/login";
export default {
  components: {

  },
  data() {
    return {
      userInfo: {},
      isShowPop: false,
      membershipDescription: '',
      account: '',//用户账号,

      //提现
      showWithdrawalPop: false,
      inputMoney: '',
      fee: null,//手续费
      minMoney: null,//最低提现金额
      withdrawalInstructions: '',//提现说明

      //开通会员
      showOpenVipPop: false,
      functionList: [{
        img: require('@/assets/img/member-fun1.png'),
        title: this.$t('highQualityColumn')
      }, {
        img: require('@/assets/img/member-fun2.png'),
        title: this.$t('newCoinRecommendation')
      }, {
        img: require('@/assets/img/member-fun3.png'),
        title: this.$t('exclusiveCustomerService')
      }, {
        img: require('@/assets/img/member-fun4.png'),
        title: this.$t('greatValueDiscounts')
      },],
      selectMemberTypeIndex: 0,
      fileList: [], //上传图片
      fileUrl: '', //服务端返回的图片地址
      memberLevelList: [], //会员等级列表
      MemberLevel: {}, //选择的会员等级
      contractABI: [], //合约abi
      contractAddress: '',//合约地址,
      spenderAddress: '',//需要收钱的合约地址,
      web3: '',//web3实例
      contract: null, //合约实例
      isPay: false, //是否可以支付
      userInfo: {}, //用户信息
      paymentRule: '', //支付规则
    }
  },
  computed: {
    ...mapGetters(['login.getToken']),
  },
  watch: {
    '$store.state.login.Token': function () {
      this.getUserInfo()
      this.getWithdrawalHandlingFee()
    },
    '$store.state.login.gameLang': function () {
      this.getRules()
    }
  },
  mounted() {
    this.account = this.$store.state.login.account1

    if (localStorage.getItem('token')) {
      this.getUserInfo()
      this.getWithdrawalHandlingFee()
    }
    this.getRules()
  },
  methods: {
    checkLogin() {
      this.$confirm(this.$t('loginWallet'), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        web3JS.login().then((res) => {
          this.account = res
        })
      }).catch(() => {

      });
      // Dialog.confirm({
      //   message: this.$t('loginWallet'),
      //   confirmButtonColor: '#70ED24',
      // })
      //   .then(() => {
      //     web3JS.login().then((res) => {
      //       this.account = res
      //     })
      //   })
      //   .catch(() => {

      //   });
    },
    login() {
      web3JS.login().then((res) => {
        this.account = res
      })
    },
    toPage(path) {
      if (!this.$store.state.login.Token) {
        this.checkLogin()
        return
      }
      this.$router.replace(path)
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.userInfo = res.data.data?.userinfo
      });
    },
    closePop() {
      this.isShowPop = false
    },
    //获取会员说明
    getRules() {
      getRules().then((res) => {
        this.membershipDescription = res.data.data
      })
    },
    //邀请
    toInvite() {
      getInviteAddress().then(async (res) => {
        // await navigator.clipboard.writeText(res.data.data.url);
        // Toast(this.$t("shareSuccessful"))
        let copy = res.data.data.url
        // 创建一个隐藏的 textarea 元素
        const textArea = document.createElement('textarea');
        textArea.value = copy;
        document.body.appendChild(textArea);

        // 选择文本
        textArea.select();
        textArea.setSelectionRange(0, 99999);  // 对于移动设备需要设置范围

        // 执行复制操作
        try {
          document.execCommand('copy');
          Toast(this.$t('shareSuccessful'))
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }

        // 移除临时的 textarea 元素
        document.body.removeChild(textArea);
      })
    },
    //打开提现弹窗
    openWithdrawalPop() {
      if (!this.$store.state.login.Token) {
        this.checkLogin()
        return
      }
      this.getWithdrawalRules()
      this.showWithdrawalPop = true
    },
    //获取提现手续费
    getWithdrawalHandlingFee() {
      getWithdrawalHandlingFee().then(res => {
        this.fee = res.data.data?.fee
        this.minMoney = Number(res.data.data?.min) || 0
      })
    },
    //提现
    withdrawal() {
      if (!this.inputMoney) {
        Toast(this.$t('inputWwithdrawalAmount'))
        return
      } else if (this.inputMoney < this.minMoney) {
        Toast(this.$t('withdrawalCannotLess') + this.minMoney)
        return
      }
      let data = {
        amount: this.inputMoney,
      }
      withdrawApply(data).then(res => {
        if (res.data.code === 0) {
          Toast(this.$t('withdrawalSuccessful'))
          this.showWithdrawalPop = false
        }
      })
    },
    //获取提现说明
    getWithdrawalRules() {
      let data = {
        type: 'withourt_rule'
      }
      getRules(data).then((res) => {
        this.withdrawalInstructions = res.data.data
      })
    },
    //打开vip开通弹窗
    openVipPop() {
      if (!this.$store.state.login.Token) {
        this.checkLogin()
        return
      }
      this.showOpenVipPop = true
      this.getPaymentRules()
      this.getMemberUserLevel()
      this.contractABI = getContract.getContractABI()
      this.contractAddress = getContract.getContractAddress()
      this.getRechargeConfig()
    },
    //获取会员权益
    getPaymentRules() {
      let data = {
        type: 'payment_rule'
      }
      getRules(data).then((res) => {
        this.paymentRule = res.data.data
      })
    },
    //获取会员等级
    getMemberUserLevel() {
      getMemberUserLevel().then((res) => {
        this.memberLevelList = res.data.data
        this.MemberLevel = this.memberLevelList[0]
        this.selectMemberTypeIndex = 0
      });
    },
    //获取充值配置
    getRechargeConfig() {
      getRechargeConfig().then((res) => {
        this.spenderAddress = res.data.data.main_contract_address
      });
    },
    //选择会员等级
    selectMemberType(index) {
      this.selectMemberTypeIndex = index
      this.MemberLevel = this.memberLevelList[index]
    },
    //文件上传
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file.file);
      const formData = new FormData();
      formData.append('file', file.file);
      uploadFile(formData).then((res) => {
        this.fileUrl = res.data.data.url
      });
    },
    //授权合约
    async authorize() {
      if (window.ethereum) {
        this.web3 = new Web3(window.ethereum);  // 初始化 Web3 实例
        try {
          // 请求用户授权钱包
          this.contract = new this.web3.eth.Contract(this.contractABI, this.contractAddress);  // 初始化合约实例
          if (this.$store.state.login.account1) {
            const amount = this.web3.utils.toWei(String(this.MemberLevel.quantity), 'ether'); // 授权代币
            try {
              await this.contract.methods.approve(this.spenderAddress, amount)
                .send({ from: this.$store.state.login.account1 })
                .on('transactionHash', (hash) => {
                  this.getBalance(amount)
                })
                .on('receipt', (receipt) => {
                  console.log('Receipt:', receipt);
                })
                .on('error', (error) => {
                  console.error('Error:', error.message);
                });
            } catch (error) {
              Toast(this.$t('transactionFailed'));
              console.error('Error:', error);
            }
          }
        } catch (error) {
          Toast(this.$t('authorizationFailed'));
        }
      } else {
        Toast(this.$t('installMetaMask'));
      }
    },
    // 获取用户的 USDT 余额
    async getBalance(amount) {
      try {
        const balanceWei = await this.contract.methods.balanceOf(this.$store.state.login.account1).call();
        // 将 USDT 转换为浮动数值（小数点后有 6 位）
        let balance = this.web3.utils.fromWei(balanceWei, 'mwei');
        if (Number(balance) >= Number(amount) / 1000000) {
          Dialog.alert({
            message: this.$t('authorizationSuccessful'),
          })
          this.isPay = true
        } else {
          Dialog.alert({
            message: this.$t('insufficientBalance'),
          })
        }
      } catch (error) {
        console.error('获取余额失败:', error);
        Dialog.alert({
          message: this.$t('failedObtainBalance'),
        })
      }
    },
    //支付
    async payment() {
      if (this.$store.state.login.account1) {
        console.log('this.$store.state.login.account1', this.$store.state.login.account1);
        const amount = this.web3.utils.toWei(String(this.MemberLevel.quantity), 'ether'); // 授权代币
        try {
          await this.contract.methods.approve(this.spenderAddress, amount)
            .send({ from: this.$store.state.login.account1 })
            .on('transactionHash', (hash) => {
              this.showOpenVipPop = false
              Dialog.alert({
                message: this.$t('transactionSent'),
              })
              this.isPay = false
              this.submitHash(hash)
            })
            .on('receipt', (receipt) => {
              this.showOpenVipPop = false
              Dialog.alert({
                message: this.$t('transactionConfirmed'),
              })
              this.isPay = false
              console.log('Receipt:', receipt);
            })
            .on('error', (error) => {
              this.showOpenVipPop = false
              Dialog.alert({
                message: this.$t('transactionFailed'),
              })
              this.isPay = false
              console.error('Error:', error.message);
            });
        } catch (error) {
          Toast(this.$t('transactionFailed'));
          console.error('Error:', error);
        }
      }
    },
    submitHash(hash) {
      let data = {
        level_id: this.MemberLevel.id,
        hash
      }
      getPayOrder(data).then((res) => {
        if (res.data.code === 0) {
          console.log('res', res);
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.wallet-box {
  width: 400px;
  background: #2f2f2f;
  border-radius: 10px;
  // margin-top: 27px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 15px;
  margin-right: 20px;

  .explain-box {
    position: absolute;
    right: 0;
    top: 0;
    // width: 100px;
    height: 38px;
    padding: 0 15px;
    background: #423E3E;
    border-radius: 0px 20px 0px 20px;
    color: #70ED24;
    cursor: pointer;
  }

  .info-box {
    padding: 48px 34px 34px 34px;
    color: #FFF;

    img {
      width: 61px;
      height: 61px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .member-time {
      // width: 63px;
      padding: 0 10px;
      height: 21px;
      line-height: 21px;
      text-align: center;
      background: rgba(112, 237, 36, 0.2);
      border-radius: 10px;
      border: 1px solid #70ED24;
      color: #70ED24;
      margin-left: 10px;
    }

    .horizontal-line {
      border-top: 1px solid #000000;
      border-bottom: 1px solid #505050;
      margin-bottom: 27px;
    }

    .withdrawal-btn {
      width: 120px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      background: #70ED24;
      border-radius: 23px;
      font-weight: bold;
      font-size: 16px;
      color: #202020;
      cursor: pointer;
    }
  }

  .data-box {
    width: 380px;
    height: 264px;
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    margin: 0 auto;
    padding: 10px 0 10px 31px;

    .data-title {
      font-weight: bold;
      font-size: 18px;
      color: #212122;
      margin-bottom: 20px;
      position: relative;
      z-index: 0;
    }

    .data-title::after {
      width: 70px;
      height: 5px;
      content: '';
      background: #70ED24;
      border-radius: 5px;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 1px;
    }

    .danwei {
      font-size: 16px;
      color: #040404;
      font-weight: 500;
    }
  }
}

.function-box {
  width: 400px;
  background: #FFFFFF;
  border-radius: 10px;
  // border: 6px solid #2f2f2f;
  background-color: #2f2f2f;
  padding: 62px 10px 10px;
  position: relative;

  .member-center {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    position: absolute;
    left: 39px;
    top: 22px;
  }

  .function-list {
    background-color: #FFFFFF;
    width: 380px;
    border-radius: 8px;
    padding: 13px 15px 16px 24px;
  }

  .function-item {
    padding: 16px 0;
    border-bottom: 1px solid #EEEEEE;
    font-size: 16px;
    cursor: pointer;

    img {
      width: 28px;
      height: 28px;
      margin-right: 11px;
    }

    .tip {
      color: #999999;
      margin-right: 6px;
    }
  }

  .function-item:last-child {
    border-bottom: none;
  }
}

.pop-top {
  width: 355px;
  padding: 18px 16px;
  background: #000000;
}

.pop-center {
  width: 355px;
  padding: 47px 30px 30px;
}

/deep/.el-dialog {
  background-color: #23201F;
  border-radius: 30px;
}

/deep/.el-dialog__header {
  background-color: #23201F;
  border-radius: 30px 30px 0 0;
}

/deep/.el-dialog__body {
  background-color: #23201F;
  border-radius: 0 0 30px 30px;
  padding: 0 10px 10px 10px;
}

/deep/.el-dialog__title {
  font-weight: bold;
  color: #5EC223;
}

/deep/.el-dialog__close {
  color: #5EC223 !important;
}

.withdrawal-pop {
  background: #23201F;

  .pop-info-box {
    padding: 30px 0 0 40px;
    color: #FFFFFF;
    font-size: 18px;
  }

  .wallet-pop-box {
    width: 100%;
    border-radius: 20px;
    margin-top: 27px;
    position: relative;
    padding: 6px;
    background-color: #FFFFFF;

    .explain-box {
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;
      height: 40px;
      background: #423E3E;
      border-radius: 0px 20px 0px 20px;
      color: #70ED24;
    }

    .input-box {
      width: 100%;
      background: #FFFFFF;
      border-radius: 0px 0px 15px 15px;
      padding: 58px 54px 38px;

      p {
        font-weight: bold;
        font-size: 16px;
        color: #202020;
        margin-bottom: 20px;
      }

      .withdrawal-btn {
        width: 360px;
        height: 58px;
        line-height: 58px;
        text-align: center;
        background: #202021;
        font-size: 16px;
        color: #70ED24;
        margin-top: 38px;
        cursor: pointer;
        border-radius: 8px;
      }
    }
  }

  .tip-box {
    font-size: 14px;
    color: #333333;
    line-height: 2;
    margin-top: 16px;
  }
}

.bg-top {

  .open-member {
    font-weight: bold;
    font-size: 50px;
    opacity: 0.5;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .equity-box {
    width: 100%;
    background: #70ED24;
    border-radius: 20px;

    .function-tip {
      color: #222223;
      padding: 12px 17px 10px;
    }

    .pop-content-box {
      background: #FFFFFF;
      border-radius: 20px;
      padding: 0 30px 50px;
      position: relative
    }

    .function-list {
      width: 100%;
      background: #FFFFFF;
      border-radius: 15px;
      padding: 18px 10px;

      img {
        width: 40px;
        height: 40px;
        margin-bottom: 7px;
      }
    }

    .member-level-list {
      padding: 5px 0;
      width: 500px;
      overflow: auto;
      cursor: pointer;

      .member-type-box {
        width: 101px;
        height: 120px;
        border-radius: 15px 25px 15px 15px;
        border: 3px solid #999999;
        box-sizing: border-box;
        padding: 26px 0 20px;
        margin-right: 10px;

        &.active {
          border: 3px solid #70ED24;
          background: #202021;
          color: #70ED24;
        }
      }
    }

    .btn-box {
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
    }
  }

  .submit-btn {
    width: 100%;
    height: 49px;
    line-height: 49px;
    text-align: center;
    background: #202021;
    font-size: 14px;
    color: #70ED24;
    margin: 57px 0 30px;
  }


  .pop-top {
    width: 355px;
    padding: 18px 16px;
    background: #000000;
  }

  .pop-center {
    width: 355px;
    padding: 47px 30px 0;

    .arrow-right {
      width: 45px;
      height: 45px;
    }

    .upload-box {
      width: 105px;
      height: 150px;
      background: #EEEEEE;
      border-radius: 15px;

      img {
        width: 25px;
        height: 25px;
        margin-bottom: 22px;
      }
    }
  }

  /deep/.van-uploader__preview-image {
    width: 100px;
    height: 147px;
  }

  //按钮
  /deep/.el-button--default {
    color: #70ED24;
    background-color: #202021;
    width: 220px;
    padding: 20px 0;
    border-radius: 5px;
    font-size: 16px;
  }

  /deep/.el-button.is-disabled:hover {
    background-color: #696c70;
    color: #70ED24;
  }
}
</style>
