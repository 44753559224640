<template>
    <div class="p-10">
        <div class="fr-ac">
            <p class="bold fs-16 ml-15">{{ $t('consulting') }}</p>
        </div>
        <div class="content-box">
            <div class="item-box fr-ac" v-for="(item, index) in dataList" :key="index"
                :class="index % 2 == 0 ? 'bgc' : ''" @click="$router.push('/mobile/noticeDetail?id=' + item.id)">
                <div class="green-box"></div>
                <div class="fs-14">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import { getHelpList } from '@/api/member'
export default {
    components: {

    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.getHelpList()
    },
    methods: {
        getHelpList() {
            let data = {
                page: 1,
                limit: 1000,
                type: 'news'
            }
            getHelpList(data).then(res => {
                this.dataList = res.data.data.list
            })
        }
    }
}
</script>

<style lang="less" scoped>
.content-box {
    padding: 20px 15px;
}

.item-box {
    height: 50px;
    padding: 0 16px;
}

.green-box {
    width: 10px;
    height: 10px;
    background: #70ED24;
    margin-right: 15px;
    flex-shrink: 0;
}

.bgc {
    background: #F7F8FF;
}
</style>