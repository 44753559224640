import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 正确的动态导入方式
if (/mobile/i.test(navigator.userAgent)) {
  import("amfe-flexible")
    .then(() => {
      console.log("amfe-flexible 已经被加载");
    })
    .catch((err) => {
      console.error("加载 amfe-flexible 失败", err);
    });
}
// import "amfe-flexible";
import "@/utils/css.css";
import "normalize.css";
import i18n from "@/lang";
import util from "@/utils/util.js";
// import '@/utils/web3'
// import Web3 from "@/utils/web3.min";
import { loginAPI } from "@/api/login";
// import VConsole from "vconsole";
// const vConsole = new VConsole();
// 注册全局 mixin
// Vue.mixin({
//   created() {
// if (this.$store.state.login.account1 && !this.$store.state.login.Token) {
//   loginAPI(this.$store.state.login.account1).then((res) => {
//     store.commit("login/changeToken", res.data.data.userinfo.token);
//   });
//   return;
// }
//  else if (!this.$store.state.login.account1) {
//   this.$store.watch(
//     (state) => state.login.account1, // 要监听的 Vuex 状态
//     (newCount, oldCount) => {
//       loginAPI(newCount).then((res) => {
//         store.commit("login/changeToken", res.data.data.userinfo.token);
//       });
//       unwatch(); // 监听一次后取消监听
//     }
//   );
//   return;
// }
//   },
// });

Vue.prototype.$util = util;

// Vue.use(vConsole);
Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
