<template>
    <div class="p-10">
        <!-- :style="'top:' + (statusBarHeight + 10) + 'px;padding-top:' + (statusBarHeight + 10) + 'px;'" -->
        <div class="fr-ac sticky-title">
            <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
            <p class="bold fs-16 ml-15">{{ $t('myTeam') }}</p>
        </div>
        <div class="top-box fr-ac">
            <div class="fc-ac data-box">
                <div class="green-data">{{ my_grade_sum }}</div>
                <div class="ta-c">{{ $t('accumulatedMembershipDevelopment') }}</div>
            </div>
            <div class="fc-ac data-box" style="background-color: #000000;">
                <div class="green-data">{{ my_rating_awards }}</div>
                <div class="ta-c">{{ $t('accumulatedIncome') }}(ustd)</div>
            </div>
        </div>
        <div class="table-head fr-ac-jsb">
            <div class="table-head-title">{{ $t('user') }}</div>
            <div class="table-head-title">{{ $t('profit') }}</div>
        </div>
        <div class="table-body" v-if="teamList && teamList.length > 0">
            <div class="table-row fr-ac-jsb" v-for="(item, index) in teamList" :key="index"
                :class="index % 2 == 0 ? 'bgc' : ''">
                <div class="fr-ac">
                    <img :src="item.avatar || require('@/assets/img/head.png')" alt="">
                    <div class="fc">
                        <div class="fs-14 fw-b" style="margin-bottom: 6px">{{ item.username }}</div>
                        <div><span class="gray">{{ item.createtime }}</span>&nbsp; {{ item.member_name }}</div>
                    </div>
                </div>
                <div>+{{ item.team_investment_return }}</div>
            </div>
        </div>
        <div class="table-body" v-else>
            <van-empty :description="$t('noTeam')" />
        </div>
    </div>
</template>

<script>
import util from '@/utils/util.js'
import { getTeamList } from '@/api/member'
export default {
    components: {

    },
    data() {
        return {
            teamList: [],
            statusBarHeight: 0,
            my_grade_sum: 0,//已累计发展会员
            my_rating_awards: 0,//累计收益
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.statusBarHeight = util.getStatusBarHeight()
        this.getTeamList()
    },
    methods: {
        // 我的团队列表
        getTeamList() {
            let data = {
                limit: 2000
            }
            getTeamList(data).then(res => {
                this.teamList = res.data.data.list
                this.my_grade_sum = res.data.data.my_grade_sum
                this.my_rating_awards = res.data.data.my_rating_awards
            })
        }
    }
}
</script>

<style lang="less" scoped>
.top-box {
    width: 100%;
    padding: 10px 11px 15px;
    background: #2a2a2a;
    border-radius: 20px;
    margin-top: 26px;
    color: #F5F6F8;

    .data-box {
        width: 173px;
        height: 112px;
        border-radius: 15px;
        padding-top: 18px;
    }

    .green-data {
        font-weight: 800;
        font-size: 30px;
        color: #70ED24;
        margin-bottom: 18px;
    }
}

.table-head {
    width: 345px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin: 16px auto 0;
    padding: 19px 33px 20px 20px;

    .table-head-title {
        font-weight: bold;
        font-size: 16px;
        position: relative;
        z-index: 0;
    }

    .table-head-title::after {
        width: 32px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        bottom: 2px;
        left: 0;
    }
}

.table-body {
    width: 345px;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        padding: 17px 14px;

        img {
            width: 45px;
            height: 45px;
            margin-right: 11px;
        }
    }

    .bgc {
        background: #F7F8FF;
    }
}
</style>