<template>
    <div class="fc-ac mt-20">
        <div class="content-box fc-ac">
            <div class="my-collect-title">{{ $t('consulting') }}</div>
            <div class="back-list-box fr-ac" @click="$router.go(-1)">
                <div style="margin-right: 8px;">{{ $t('backList') }}</div>
                <i class="el-icon-back"></i>
            </div>
            <div class="scroll-wrap">
                <div class="table-wrap scroll-container">
                    <div class="info-box">
                        <div class="title">{{ detail.name }}</div>
                        <div class="time">{{ detail.time }}</div>
                        <div v-if="detail.description" v-html="detail.description"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHelpDetail } from '@/api/member'

export default {
    components: {

    },
    data() {
        return {
            id: '',
            detail: {}
        }
    },
    computed: {

    },
    watch: {
        '$store.state.login.gameLang': function () {
            this.getHelpDetail()
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.getHelpDetail()
    },
    methods: {
        getHelpDetail() {
            getHelpDetail(this.id).then(res => {
                this.detail = res.data.data
            })
        }
    }
}
</script>

<style lang="less" scoped>
.content-box {
    width: 1200px;
    height: 907px;
    background: #2E2E2E;
    border-radius: 10px;
    position: relative;

    .my-collect-title {
        font-weight: 800;
        font-size: 18px;
        color: #70ED24;
        position: absolute;
        top: 21px;
        left: 36px;
    }

    .back-list-box {
        position: absolute;
        top: 22px;
        right: 36px;
        color: #70ED24;
        cursor: pointer;
    }

    .scroll-wrap {
        width: 1180px;
        height: 832px;
        background: #FFFFFF;
        padding: 18px 0;
        margin-top: 65px;
        border-radius: 8px;
    }

    .table-wrap {
        width: 1165px;
        height: 800px;
        padding: 0 22px;
        overflow: scroll;
        overflow-x: hidden;
    }
}

.info-box {
    padding: 0 25px;
}

.title {
    font-weight: bold;
    font-size: 28px;
    margin-top: 39px;
    margin-bottom: 18px;
}

.time {
    font-size: 14px;
    color: #999999;
    margin-bottom: 20px;
}
</style>