import LangCn from './static/cn'
import LangEn from './static/en'


import VueI18n from 'vue-i18n'
import Vue from 'vue'
import store from '@/store'
Vue.use(VueI18n)

const i18n = new VueI18n({
	locale:  store.state.login.gameLang || 'CN',  // 默认选择的语言
	messages: {
		'CN': LangCn, //中文
		'EN': LangEn, //英文

	}
})
  export default i18n