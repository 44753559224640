<template>
    <div class="">
        <div class="top-box fr-ac">
            <div class="fc-ac data-box">
                <div class="green-data">{{ my_grade_sum }}</div>
                <div class="ta-c">{{ $t('accumulatedMembershipDevelopment') }}</div>
            </div>
            <div class="fc-ac data-box" style="background-color: #000000;">
                <div class="green-data">{{ my_rating_awards }}</div>
                <div class="ta-c">{{ $t('accumulatedIncome') }}(ustd)</div>
            </div>
        </div>
        <div class="team-wrap fc-ac">
            <div class="my-team-title">{{ $t('myTeam') }}</div>
            <div class="scroll-wrap">
                <div class="table-wrap scroll-container">
                    <div class="table-head fr-ac-jsb">
                        <div class="table-head-title">{{ $t('user') }}</div>
                        <div class="table-head-title">{{ $t('profit') }}</div>
                    </div>
                    <div class="table-body" v-if="teamList && teamList.length > 0">
                        <div class="table-row fr-ac-jsb" v-for="(item, index) in teamList" :key="index"
                            :class="index % 2 == 0 ? 'bgc' : ''">
                            <div class="fr-ac">
                                <img :src="item.avatar || require('@/assets/img/head.png')" alt="">
                                <div class="fc">
                                    <div class="fs-18 fw-b" style="margin-bottom: 6px">{{ item.username }}</div>
                                    <div><span class="gray fs-14">{{ item.createtime }}</span>&nbsp; {{ item.member_name
                                        }}</div>
                                </div>
                            </div>
                            <div class="fs-24 fw-b">+{{ item.team_investment_return }}</div>
                        </div>
                    </div>
                    <div class="table-body" v-else>
                        <van-empty style="margin-top: 100px;" :description="$t('noTeam')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util.js'
import { getTeamList } from '@/api/member'
export default {
    components: {

    },
    data() {
        return {
            teamList: [],
            statusBarHeight: 0,
            my_grade_sum: 0,//已累计发展会员
            my_rating_awards: 0,//累计收益
        }
    },
    computed: {

    },
    watch: {
        '$store.state.login.gameLang': function () {
            this.getTeamList()
        }
    },
    mounted() {
        this.statusBarHeight = util.getStatusBarHeight()
        this.getTeamList()
    },
    methods: {
        // 我的团队列表
        getTeamList() {
            let data = {
                limit: 2000
            }
            getTeamList(data).then(res => {
                this.teamList = res.data.data.list
                this.my_grade_sum = res.data.data.my_grade_sum
                this.my_rating_awards = res.data.data.my_rating_awards
            })
        }
    }
}
</script>

<style lang="less" scoped>
.top-box {
    width: 860px;
    height: 120px;
    border-radius: 10px;
    padding: 10px 11px 15px;
    background: #2a2a2a;
    color: #F5F6F8;
    margin-bottom: 24px;

    .data-box {
        width: 457px;
        height: 93px;
        border-radius: 10px;
        padding-top: 8px;
    }

    .green-data {
        font-weight: 800;
        font-size: 30px;
        color: #70ED24;
        margin-bottom: 18px;
    }
}

.team-wrap {
    width: 860px;
    height: 850px;
    background: #2a2a2a;
    border-radius: 10px;
    position: relative;

    .my-team-title {
        font-weight: 800;
        font-size: 18px;
        color: #70ED24;
        position: absolute;
        top: 21px;
        left: 36px;
    }

    .scroll-wrap {
        width: 840px;
        height: 775px;
        background: #FFFFFF;
        padding: 18px 0;
        margin-top: 65px;
        border-radius: 8px;
    }

    .table-wrap {
        width: 826px;
        height: 744px;
        padding: 0 22px;
        overflow: scroll;
        overflow-x: hidden;

        .table-head {
            width: 100%;
            height: 55px;
            background: #F7F7F7;
            border-radius: 5px;
            padding: 26px 76px 26px 26px;

            .table-head-title {
                font-weight: bold;
                font-size: 16px;
                position: relative;
                z-index: 0;
            }

            .table-head-title::after {
                width: 32px;
                height: 5px;
                content: '';
                background: #70ED24;
                border-radius: 5px;
                position: absolute;
                z-index: -1;
                bottom: 2px;
                left: 0;
            }
        }

        .table-body {
            width: 100%;
            background: #FFFFFF;
            margin: 0 auto;

            .table-row {
                padding: 17px 14px;

                img {
                    width: 60px;
                    height: 60px;
                    margin-right: 20px;
                }
            }

            .bgc {
                background: #F7F8FF;
            }
        }
    }
}
</style>