import { render, staticRenderFns } from "./vipSelection.vue?vue&type=template&id=37c82676&scoped=true&"
import script from "./vipSelection.vue?vue&type=script&lang=js&"
export * from "./vipSelection.vue?vue&type=script&lang=js&"
import style0 from "./vipSelection.vue?vue&type=style&index=0&id=37c82676&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c82676",
  null
  
)

export default component.exports