<template>
    <div class="p-10">
        <div class="fr-ac">
            <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
            <p class="bold fs-16 ml-15">{{ $t('consulting') }}</p>
        </div>
        <div class="content-box">
            <div class="title">{{ detail.name }}</div>
            <div class="time">{{ detail.time }}</div>
            <div v-if="detail.description" v-html="detail.description"></div>
        </div>
    </div>
</template>

<script>
import { getHelpDetail } from '@/api/member'

export default {
    components: {

    },
    data() {
        return {
            id: '',
            detail: {}
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.id = this.$route.query.id
        this.getHelpDetail()
    },
    methods: {
        getHelpDetail() {
            getHelpDetail(this.id).then(res => {
                this.detail = res.data.data
            })
        }
    }
}
</script>

<style lang="less" scoped>
.content-box {
    padding: 0 25px;
}

.title {
    font-weight: bold;
    font-size: 19px;
    margin-top: 39px;
    margin-bottom: 18px;
}

.time {
    font-size: 12px;
    color: #999999;
    margin-bottom: 20px;
}
</style>