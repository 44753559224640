<template>
    <div>
        <div class="search-box fr-ac" @click="onSearch()">
            <van-icon name="search" color="#5F626B" size="20" />
            <p>{{ $t('searchPlaceholder') }}</p>
        </div>
        <van-swipe class="swipe-img-box" width="220" ref="swipeImg" :autoplay="5000">
            <van-swipe-item v-for="(item, index) in bannerList" :key="item.id">
                <img class="swiper-img" :src="item.images" alt="">
            </van-swipe-item>
            <template #indicator> </template>
        </van-swipe>

        <!-- 新币直播 -->
        <div class="title-box fr-ac-jsb">
            <div class="title">{{ $t('newLive') }}</div>
            <div class="more-btn fr-ac-jc" @click="changeTab(1)">
                <p>{{ $t('more') }}</p>
                <img src="@/assets/img/right_black.png" alt="">
            </div>
        </div>
        <div v-if="xbDataList && xbDataList.length > 0">
            <van-swipe class="swipe-box" :show-indicators="false" width="170">
                <van-swipe-item v-for="(item, index) in xbDataList" :key="index">
                    <div class="hot-list-box" @click="toDetail(item.token_contract, item.view_count)">
                        <div class="white-bg"></div>
                        <div class="content-box">
                            <van-row gutter="16">
                                <van-col span="8">
                                    <img class="label-icon" src="@/assets/img/head.png" alt="">
                                </van-col>
                                <van-col span="16" class="fc-jsb" style="height: 50px;">
                                    <div class="hot-title yc-more" style="overflow-wrap: break-word;">{{ item.token_name
                                        }}
                                    </div>
                                    <div class="fr-ac-jsb">
                                        <div class="fw-b">${{ item.price.toFixed(6) }}</div>
                                        <!-- <div style="color: #999999;">22d</div> -->
                                    </div>
                                </van-col>
                            </van-row>
                            <van-row class="hot-center-box">
                                <van-col span="12">
                                    <div>${{ item.open_price.toFixed(6) }}</div>
                                    <div class="green-bg-box fr-ac-jc"
                                        :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                        <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                            src="@/assets/img/icon_top.png" alt="">
                                        <p>{{ item.price_diff.toFixed(0) }}%</p>
                                    </div>
                                </van-col>
                                <!-- <van-col span="14">
                                    <div class="hot-chart" id="chart"></div>
                                </van-col> -->
                            </van-row>
                            <van-row>
                                <div class="fr-ac-jsb" style="margin-top: 10px;">
                                    <div class="fw-b">{{ $t('marketCap') }}</div>
                                    <div class="light-gray">${{ formattedMarketCap(item.market_cap) }}</div>
                                </div>
                                <div class="fr-ac-jsb" style="margin-top: 8px;">
                                    <div class="fw-b">{{ $t('mobility') }}</div>
                                    <div class="light-gray">${{ formattedMarketCap(item.liquidity) }}</div>
                                </div>
                                <div class="fr-ac-jsb" style="margin-top: 8px;">
                                    <div class="fw-b">{{ $t('txns') }}</div>
                                    <div class="light-gray">{{ item.txcount_24h || 0 }}</div>
                                </div>
                                <div class="fr-ac-jsb" style="margin-top: 8px;">
                                    <div class="fw-b">{{ $t('holders') }}</div>
                                    <div class="light-gray">{{ item.holders || 0 }}</div>
                                </div>
                            </van-row>
                        </div>
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div v-else>
            <van-empty :description="$t('noData')" />
        </div>

        <!-- 新币 -->
        <div class="new-currency-tab-wrap fr-ac-jc">
            <div class="new-currency-tab-item fr-ac-jc" :class="{ 'active': 0 === curNewCurrencyIndex }"
                @click="changeNewCurrencyTab(0)">{{ $t('potentialCurrency')
                }}</div>
            <div class="new-currency-tab-item fr-ac-jc" :class="{ 'active': 1 === curNewCurrencyIndex }"
                @click="changeNewCurrencyTab(1)">{{ $t('popularCoins')
                }}</div>
        </div>
        <div>
            <div class="new-currency-content-top fr-ac-je">
                <div class="fr-ac" @click="changeTab(2)">
                    <p>{{ $t('more') }}</p>
                    <img src="@/assets/img/right_white.png" alt="">
                </div>
            </div>
            <div v-if="qlrmDataList && qlrmDataList.length > 0">
                <van-swipe class="new-currency-swipe-box" width="375">
                    <van-swipe-item v-for="(item, index) in qlrmDataList" :key="index">
                        <div class="new-currency-content-center"
                            @click="toDetail(item.token_contract || item.address, item.view_count)">
                            <van-row class="info-top fr-ac">
                                <van-col span="4">
                                    <img class="label-icon" src="@/assets/img/head.png" alt="">
                                </van-col>
                                <van-col span="16" class="fc-jsb" style="height: 40px;">
                                    <div class="hot-title">{{ item.token_name || item.symbol }}</div>
                                    <div class="gray" v-if="item.token_symbol">{{ item.token_symbol }}</div>
                                </van-col>
                                <!-- <van-col span="9">
                                <div class="hot-chart" id="new-currency-chart"></div>
                            </van-col> -->
                                <van-col span="4">
                                    <div>${{ item.price.toFixed(6) }}</div>
                                    <div class="green-bg-box fr-ac-jc" v-if="curNewCurrencyIndex === 0"
                                        :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                        <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                            src="@/assets/img/icon_top.png" alt="">
                                        <p>{{ item.price_diff.toFixed(0) }}%</p>
                                    </div>
                                    <div class="green-bg-box fr-ac-jc" v-else
                                        :style="item.price_change_percent < 0 ? 'background: #FF0000;' : ''">
                                        <img :class="item.price_change_percent < 0 ? 'rotate' : ''" class="top-icon"
                                            src="@/assets/img/icon_top.png" alt="">
                                        <p>{{ item.price_change_percent.toFixed(0) }}%</p>
                                    </div>
                                </van-col>
                            </van-row>
                            <van-row gutter="60">
                                <van-col span="12">
                                    <div class="fr-ac-jsb mt-10">
                                        <div class="fw-b">{{ $t('marketCap') }}</div>
                                        <div class="light-gray">${{ formattedMarketCap(item.market_cap) }}</div>
                                    </div>
                                    <div class="fr-ac-jsb mt-10">
                                        <div class="fw-b">{{ $t('mobility') }}</div>
                                        <div class="light-gray">${{ formattedMarketCap(item.liquidity) }}</div>
                                    </div>
                                </van-col>
                                <van-col span="12">
                                    <div class="fr-ac-jsb mt-10">
                                        <div class="fw-b">{{ $t('txns') }}</div>
                                        <div class="light-gray">{{ item.txcount_24h || item.swaps || 0 }}</div>
                                    </div>
                                    <div class="fr-ac-jsb mt-10">
                                        <div class="fw-b">{{ $t('holders') }}</div>
                                        <div class="light-gray">{{ item.holders || item.holder_count || 0 }}</div>
                                    </div>
                                </van-col>
                            </van-row>
                        </div>
                    </van-swipe-item>
                    <template #indicator> </template>
                </van-swipe>
            </div>
            <div v-else>
                <van-empty :description="$t('noData')" />
            </div>
        </div>

        <!-- 交易热点 -->
        <div class="title-box fr-ac-jsb">
            <div class="title">{{ $t('tradingHotspots') }}</div>
            <div class="more-btn fr-ac-jc" @click="changeTab(4)">
                <p>{{ $t('more') }}</p>
                <img src="@/assets/img/right_black.png" alt="">
            </div>
        </div>
        <div v-if="rdDataList && rdDataList.length > 0">
            <van-swipe class="new-currency-swipe-box" width="375">
                <van-swipe-item v-for="(item, index) in rdDataList" :key="index"
                    @click="toDetail(item.token_contract, item.view_count)">
                    <div class="new-currency-content-center">
                        <van-row class="info-top fr-ac">
                            <van-col span="4">
                                <img class="label-icon" src="@/assets/img/head.png" alt="">
                            </van-col>
                            <van-col span="16" class="fc-jsb" style="height: 40px;padding-right: 10px;">
                                <div class="hot-title">{{ item.token_name }}</div>
                                <div class="gray">{{ item.token_symbol }}</div>
                            </van-col>
                            <!-- <van-col span="9">
                            <div class="hot-chart" id="new-currency-chart"></div>
                        </van-col> -->
                            <van-col span="4">
                                <div>${{ item.price.toFixed(6) }}</div>
                                <div class="green-bg-box fr-ac-jc"
                                    :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                    <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                        src="@/assets/img/icon_top.png" alt="">
                                    <p>{{ item.price_diff.toFixed(0) }}%</p>
                                </div>
                                <!-- <div class="green-bg-box fr-ac-jc">
                                <img class="top-icon" src="@/assets/img/icon_top.png" alt="">
                                <p>13.1%</p>
                            </div> -->
                            </van-col>
                        </van-row>
                        <van-row gutter="60">
                            <van-col span="12">
                                <div class="fr-ac-jsb mt-10">
                                    <div class="fw-b">{{ $t('marketCap') }}</div>
                                    <div class="light-gray">${{ formattedMarketCap(item.market_cap) }}</div>
                                </div>
                                <div class="fr-ac-jsb mt-10">
                                    <div class="fw-b">{{ $t('mobility') }}</div>
                                    <div class="light-gray">${{ formattedMarketCap(item.liquidity) }}</div>
                                </div>
                            </van-col>
                            <van-col span="12">
                                <div class="fr-ac-jsb mt-10">
                                    <div class="fw-b">{{ $t('txns') }}</div>
                                    <div class="light-gray">{{ item.txcount_24h || 0 }}</div>
                                </div>
                                <div class="fr-ac-jsb mt-10">
                                    <div class="fw-b">{{ $t('holders') }}</div>
                                    <div class="light-gray">{{ item.holders || 0 }}</div>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </van-swipe-item>
                <template #indicator> </template>
            </van-swipe>
        </div>
        <div v-else>
            <van-empty :description="$t('noData')" />
        </div>

        <!-- VIP精选 -->
        <div class="title-box fr-ac-jsb">
            <div class="title">{{ $t('VIPSelection') }}</div>
            <div class="more-btn fr-ac-jc" @click="changeTab(5)">
                <p>{{ $t('more') }}</p>
                <img src="@/assets/img/right_black.png" alt="">
            </div>
        </div>
        <div v-if="vipDataList && vipDataList.length > 0">
            <div class="vip-box" v-for="(item, index) in vipDataList" :key="item.id"
                @click="toDetail(item.token, item.view_count, 'vip')">
                <van-col span="4">
                    <img class="label-icon" :src="item.icon" alt="">
                </van-col>
                <van-col span="20" class="fc-jsb" style="height: 70px;">
                    <div class="fs-16">{{ item.name }}</div>
                    <div>{{ item.token }}</div>
                    <!-- <div class="gray">{{ item.add_date }}</div> -->
                    <div class="fr-ac">
                        <div class="mr-10">${{ Number(item.compare.now_price).toFixed(6) }}</div>
                        <div class="green-bg-box fr-ac-jc mr-10" style="margin-top: 0;" v-if="item.price_diff"
                            :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                            <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                src="@/assets/img/icon_top.png" alt="">
                            <p>{{ item.price_diff.toFixed(2) }}%</p>
                        </div>
                        <div class="gray">{{ $t('pushTime') + "：" + item.add_date }}</div>
                    </div>
                </van-col>
                <!-- <van-col class="fc-jsb" span="16" style="height: 45px;">
                <div class="fr-ac">
                    <div class="vip-title">ACT</div>
                    <div class="vip-chart" id="vip-chart"></div>
                </div>
                <div class="gray">合约代码：{{ item.token }}</div>
            </van-col>
            <van-col span="4">
                <div>$0.3403</div>
                <div class="green-bg-box fr-ac-jc">
                    <img class="top-icon" src="@/assets/img/icon_top.png" alt="">
                    <p>13.1%</p>
                </div>
            </van-col> -->
            </div>
        </div>
        <div v-else>
            <van-empty :description="$t('noData')" />
        </div>
        <!-- 搜索弹窗 -->
        <van-popup v-model="isShowSearch" round @close="closeSearchPop()" z-index="100">
            <div class="search-wrap">
                <van-search v-model="searchKey" :placeholder="$t('searchPlaceholder')" shape="round" show-action
                    @input="handleInput">
                    <template #action>
                        <div @click="closeSearchPop()">{{ $t('cancel') }}</div>
                    </template>
                </van-search>
                <!-- <van-list v-model="loading" :finished="finished" :loading-text="$t('loading')" :immediate-check="false"
                    :finished-text="$t('noMore')" @load="getSearchList"> -->
                <div class="search-list fc">
                    <div class="fr-ac search-item" v-for="(item, index) in searchDataList" :key="index"
                        @click="toDetail(item.virtual_id, item.view_count, 'vip')">
                        <img src="@/assets/img/head.png" alt="">
                        <div class="fs-12 fr-ac">{{ item.name }}</div>
                    </div>
                </div>
                <!-- </van-list> -->
            </div>
        </van-popup>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { getData, getVipData } from '@/api/api'
import { getBannerList } from '@/api/login'
import { mapState } from 'vuex'
import Decimal from 'decimal.js';
import { Toast } from "vant";

export default {
    props: {
        // 用户等级 0是非会员
        level: {
            type: [Number, String],
            default: 0,
        }
    },
    data() {
        return {
            chartInstance: null,
            bannerList: [],// 轮播图
            curNewCurrencyIndex: 0, // 当前新币tab索引
            xbDataList: [], // 新币直播数据
            rmDataList: [], // 热门新币数据
            qlDataList: [], // 潜力新币数据
            qlrmDataList: [], // 潜力热门新币数据
            rdDataList: [], // 交易热点数据
            vipDataList: [], // VIP数据
            interval: null, // 定时器
            isShowSearch: false, //是否显示搜索弹窗
            searchKey: "", // 搜索关键词
            searchDataList: [], // 搜索结果
            page: 1,
            loading: false,
            finished: false,
            timeout: null
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
        //市值
        formattedMarketCap(value) {
            return (value) => {
                return this.$util.formatMarketCap(Number(value) || 0);
            }
        },
    },
    watch: {
        chain(newVal, oldVal) {
            this.getXbData()//新币直播
            this.getQlData()//潜力新币
            this.getRmData()//热门新币
            this.getRdData()//交易热点
            this.getVipData()//vip精选
        },
        '$store.state.login.gameLang': function () {
            this.getBannerList()
        }
        //   $route(route) {
        //     if (route.path.startsWith('/redirect/')) {
        //       return
        //     }
        //     this.getBreadcrumb()
        //   }
    },
    mounted() {
        // this.initChart('chart', 74, 40);
        // this.initChart('new-currency-chart', 74, 40);
        // this.initChart('vip-chart', 32, 16);
        this.getBannerList()
        this.getXbData()//新币直播
        this.getQlData()//潜力新币
        this.getRmData()//热门新币
        this.getRdData()//交易热点
        this.getVipData()//vip精选
        this.createMyInterval() //循环调用
    },
    beforeDestroy() {
        // 组件销毁前执行的逻辑
        this.clearMyInterval()
    },
    methods: {
        createMyInterval() {
            this.interval = setInterval(() => {
                this.getXbData()//新币直播
                this.getQlData()//潜力新币
                this.getRmData()//热门新币
                this.getRdData()//交易热点
            }, 5000);  // 每5秒检查一次服务器状态
        },
        clearMyInterval() {
            clearInterval(this.interval);
        },
        // 获取banner
        getBannerList() {
            getBannerList().then((res) => {
                this.bannerList = res.data.data
            });
        },
        changeNewCurrencyTab(index) {
            this.curNewCurrencyIndex = index
            if (this.curNewCurrencyIndex === 0) {
                this.qlrmDataList = this.qlDataList
            } else {
                this.qlrmDataList = this.rmDataList
            }
        },
        //新币直播
        getXbData() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethxb'
            } else {
                chain = 'solxb'
            }
            getData(chain).then((res) => {
                this.xbDataList = res.data.data.splice(0, 5)
            });
        },
        //潜力新币
        getQlData() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethql'
            } else {
                chain = 'solql'
            }
            getData(chain).then((res) => {
                this.qlDataList = res.data.data.splice(0, 5)
                if (this.curNewCurrencyIndex == 0) {
                    this.qlrmDataList = this.qlDataList
                }
            });
        },
        //热门新币
        getRmData() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethrm'
            } else {
                chain = 'solrm'
            }
            getData(chain).then((res) => {
                this.rmDataList = res.data.data.rank.splice(0, 5)
                if (this.curNewCurrencyIndex == 1) {
                    this.qlrmDataList = this.rmDataList
                }
            });
        },
        //交易热点
        getRdData() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethrd'
            } else {
                chain = 'solrd'
            }
            getData(chain).then((res) => {
                this.rdDataList = res.data.data.splice(0, 5)
            })
        },
        //vip精选
        getVipData() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethvip'
            } else {
                chain = 'solvip'
            }
            getVipData(chain, 'vip', 1, 3, '').then((res) => {
                this.vipDataList = res.data.data.list
                this.vipDataList.forEach(item => {
                    item.add_date = item.add_date.slice(0, 10)
                    let now_price = new Decimal(item.compare.now_price);
                    let last_price = new Decimal(item.compare.last_price);
                    if (item.compare) {
                        this.$set(item, 'price_diff', now_price.minus(last_price).dividedBy(last_price))
                    }
                })
            })
        },
        // 跳转详情页
        toDetail(address, view_count, type) {
            // if (type == 'vip' && this.level == 0) {
            //     Toast(this.$t('openVipTip'))
            //     return
            // }
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
        changeTab(index) {
            if (index === 2 && this.curNewCurrencyIndex === 1) {
                index = 3
            }
            this.$emit('changeTab', index)
        },
        //关闭搜索弹窗
        closeSearchPop() {
            this.isShowSearch = false
        },
        //打开搜索弹窗
        onSearch() {
            this.isShowSearch = true
        },
        //搜索
        handleInput(query) {
            // 清除上一次的定时器
            clearTimeout(this.timeout);

            // 设置一个新的定时器来延迟调用搜索方法
            this.timeout = setTimeout(() => {
                this.searchKey = query;
                this.page = 1;  // 重置为第一页
                this.searchDataList = [];
                this.finished = false;
                this.getSearchList();  // 执行数据加载
            }, 500); // 500ms 延迟
        },
        //获取搜索列表
        getSearchList() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethvip'
            } else {
                chain = 'solvip'
            }
            getVipData(chain, 'search', this.page, 10, this.searchKey).then((res) => {
                this.searchDataList = this.page === 1 ? res.data.data.list : [...this.searchDataList, ...res.data.data.list];

                this.loading = false;
                this.page++;
                if (this.searchDataList.length >= res.data.data.total) {
                    this.finished = true;
                }
            })
        },
        // initChart(id, width, height) {
        //     // 初始化图表实例
        //     this.chartInstance = echarts.init(document.getElementById(id), null, {
        //         width,
        //         height
        //     });
        //     // 配置图表的选项
        //     const option = {
        //         title: {
        //             text: ''
        //         },
        //         tooltip: {},
        //         legend: {
        //             data: ['销量']
        //         },
        //         xAxis: {
        //             data: []
        //         },
        //         yAxis: {},
        //         series: [{
        //             // name: '销量',
        //             type: 'line',
        //             smooth: true,
        //             data: [5, 20, 10, 10, 10, 20, 10]
        //         }],
        //         dataZoom: [
        //             {
        //                 type: 'slider',  // 可以使用滑动条进行缩放
        //                 show: true,
        //                 start: 0,  // 初始显示的起始比例
        //                 end: 100   // 初始显示的结束比例
        //             }
        //         ]
        //     };

        //     // 使用设置的配置项初始化图表
        //     this.chartInstance.setOption(option);

        //     // 在组件销毁时清理图表实例
        //     window.addEventListener('resize', this.chartInstance.resize);
        //     this.$once('hook:beforeDestroy', () => {
        //         this.chartInstance.dispose();
        //     });
        // },
    }
}
</script>

<style lang="less" scoped>
.search-box {
    background: #F7F7F7;
    border-radius: 39px;
    padding: 10px 20px 10px 10px;
    margin: 10px 15px 0;

    p {
        font-size: 14px;
        color: #999BAA;
        margin-left: 8px;
    }
}

.swipe-img-box {
    padding: 20px 15px 16px;

    .swiper-img {
        width: 195px;
        height: 137px;
        border-radius: 15px;
    }
}

.swipe-box {
    padding: 0 15px 16px;

    .hot-list-box {
        width: 153px;
        height: 234px;
        background: #F7F7F7;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
        border-radius: 7px;
        position: relative;

        .white-bg {
            width: 153px;
            height: 191px;
            background: #FFFFFF;
            border-radius: 0px 7px 7px 7px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .content-box {
            width: 153px;
            height: 234px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            font-size: 12px;
            padding: 17px 10px;

            .hot-title {
                font-weight: bold;
                font-size: 15px;
                line-height: 1;
            }

            .hot-center-box {
                margin-top: 16px;
                padding-bottom: 11px;
                border-bottom: 1px dashed #E3E3E3;
            }

            .hot-chart {
                width: 74px;
                height: 40px;
            }
        }
    }
}

.title-box {
    padding: 40px 16px 23px;

    .title {
        font-weight: bold;
        font-size: 24px;
        color: #000000;
    }

    .more-btn {
        width: 45px;
        height: 27px;
        line-height: 27px;
        background: #70ED24;
        border-radius: 4px 4px 10px 4px;
        position: relative;

        p {
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
        }

        img {
            width: 12px;
            height: 14px;
            position: absolute;
            right: -3px;
        }
    }
}

/deep/ .van-swipe__indicators {
    bottom: 0;

    .van-swipe__indicator {
        background-color: rgb(95, 98, 107);
        opacity: 1;
        width: 8px;
        height: 8px;
    }

    .van-swipe__indicator--active {
        background-color: rgb(112, 237, 36);
        width: 16px;
        height: 8px;
        border-radius: 5px;
    }
}

.new-currency-tab-wrap {
    width: 345px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin: 20px auto 10px;
    position: relative;

    .new-currency-tab-item {
        transition: all 0.5s;
        width: 165px;
        height: 40px;
        border-radius: 5px;
        color: #A2A2A2;
        font-weight: bold;
        font-size: 16px;
        position: relative;
        z-index: 0;

        &.active {
            background: #FFFFFF;
            color: #000000;
        }

        &.active::after {
            width: 62px;
            height: 5px;
            content: '';
            background: #70ED24;
            border-radius: 5px;
            position: absolute;
            z-index: -1;
            bottom: 12px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.new-currency-content-top {
    width: 345px;
    height: 30px;
    background: #292B28;
    border-radius: 7px 7px 0 0px;
    margin: 0 auto;

    p {
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        margin-right: 3px;
    }

    img {
        width: 12px;
        height: 14px;
        margin-right: 16px;
    }
}

.new-currency-swipe-box {
    padding: 0 15px 34px;

    .new-currency-content-center {
        width: 345px;
        height: 150px;
        background: #FFFCFC;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
        border-radius: 0 0 7px 7px;
        padding: 15px 15px 28px;
        font-size: 12px;

        .info-top {
            padding-bottom: 15px;
            border-bottom: 1px dashed #EEEEEE;

            .hot-title {
                font-weight: bold;
                font-size: 16px;
                line-height: 1;
            }

            .hot-chart {
                width: 74px;
                height: 40px;
            }

        }
    }
}

.vip-box {
    width: 345px;
    height: 90px;
    background: #FFFCFC;
    background-size: cover;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 7px;
    margin: 0 auto 15px;
    padding: 16px 10px;
    font-size: 12px;

    .vip-title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 6px;
    }

    .vip-chart {
        width: 32px;
        height: 18px;
        margin-left: 6px;
        margin-top: 6px;
    }
}

.search-wrap {
    width: 355px;
    height: 480px;
    background: #FFFFFF;
    // box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 22px 19px 10px;

    .search-tip {
        font-weight: bold;
        font-size: 19px;
        margin-top: 68px;
        margin-bottom: 17px;
    }

    .search-list {
        // height: 480px;
        padding-top: 10px;

        .search-item {
            width: 100%;
            height: 34px;
            background: #F2F3F7;
            border-radius: 5px;
            padding: 6px 8px;
            margin-bottom: 13px;
        }

        img {
            width: 21px;
            height: 21px;
            margin-right: 6px;
        }
    }
}
</style>