import store from "@/store";
import { loginAPI } from "@/api/login";
import { Toast } from "vant";
import i18n from "@/lang";
import des from "./des";
// import Web3 from "./web3.min";
import log from "node-forge/lib/log";
// const desiredNetwork = '97'
//判断用户是否安装MetaMask钱包插件
async function login() {
  if (typeof window.ethereum !== "undefined") {
    let addr = await window.ethereum.request({ method: "eth_requestAccounts" }); //授权连接钱包
    console.log("登录钱包地址：", addr);
    store.commit("login/changeAccount1", addr[0]);
    loginAPI(addr[0]).then((res) => {
      console.log("登录获取token：", res.data.data.userinfo.token);
      store.commit("login/changeToken", res.data.data.userinfo.token);
    });
    return addr[0];
  } else {
    Toast(i18n.t("WalletPlugInNotInstalled"));
  }
}

//监听钱包切换
// window.ethereum.on("accountsChanged", (accounts) => {
//   console.log("钱包切换", accounts);
//   if (accounts.length > 0) {
//     store.commit("login/changeAccount1", accounts[0]);
//     loginAPI(accounts[0]).then((res) => {
//       store.commit("login/changeToken", res.data.data.userinfo.token);
//     });
//   } else {
//     store.commit("login/changeAccount1", null);
//   }
//   window.location.reload();
// });

//调用登录接口
// function tologinApi(address) {
//   loginAPI(address).then((res) => {
//     store.commit("login/changeToken", res.data.data.userinfo.token);
//   });
// }

//监听链网络改变
// ethereum.on("chainChanged", () => {
//   console.log("链切换");
//   window.location.reload();
//   // router.go(0)
// });

function checkMetaMaskLoginStatus() {
  if (typeof window.ethereum !== "undefined") {
    const currentAddress = window.ethereum.selectedAddress;
    if (currentAddress) {
      console.log("MetaMask is connected with address:", currentAddress);
      return currentAddress;
    } else {
      console.log("No account is connected");
      return null;
    }
  } else {
    console.log("MetaMask is not installed");
  }
}

export default {
  login, //钱包
  checkMetaMaskLoginStatus,
};
