<template>
  <div class="p-10">
    <!-- <div class="fr-ac">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <p class="bold fs-16 ml-15">会员中心</p>
    </div> -->
    <div class="wallet-box">
      <div class="fr-ac-jc explain-box" @click="isShowPop = true">
        <van-icon name="question-o" size="16px" color="#70ED24" />
        <div style="line-height: 1;margin-left: 5px">{{ $t('membershipDescription') }}</div>
      </div>
      <div class="info-box fc">
        <div class="fr-ac mb-20">
          <img :src="userInfo.avatar || require('@/assets/img/member-head.png')" alt="">
          <div class="fc" v-if="account">
            <div class="fr mb-10">
              <div class="fs-18 fw-b" v-if="userInfo.nickname">{{ userInfo.nickname }}</div>
              <div class="member-time" v-if="userInfo.levelName">{{ userInfo.levelName }}</div>
            </div>
            <div class="fr">
              <div class="gray mr-10" v-if="userInfo.member_date">{{ $t('membershipExpiration') }}:{{
        userInfo.member_date }}</div>
              <div class="green fw-b" @click="toPage('/openMember')">{{ $t('upgradeMembership') }}</div>
            </div>
          </div>
          <div class="fc fs-20" v-else @click="login()">
            {{ $t('login') }}
          </div>
        </div>
        <div class="horizontal-line"></div>
        <div class="fr-ac-jsb">
          <div class="fc">
            <div style="margin-bottom: 4px;"><span class="fs-16">{{ $t('accountBalance') }}</span>（{{ $t('withdrawable')
              }}）</div>
            <div class="fs-24 fw-b">{{ userInfo.money || 0 }} <span class="fs-14" style="margin-left: 6px;">USTD</span>
            </div>
          </div>
          <div class="withdrawal-btn" @click="toPage('/withdrawal?money=' + userInfo.money)">{{
        $t('withdrawal') }}</div>
          <!-- <div class="withdrawal-btn" @click="$router.push('/withdrawal')">我要提现</div> -->
        </div>
      </div>
      <div class="data-box fr-ac-jsa">
        <div class="fc-ac">
          <div class="data-title">{{ $t('accumulatedIncome') }}</div>
          <div class="fs-16 gray">{{ userInfo.total_profit }}</div>
        </div>
        <div class="fc-ac">
          <div class="data-title">{{ $t('pendingReview') }}</div>
          <div class="fs-16 gray">{{ userInfo.total_withdraw }}</div>
        </div>
      </div>
    </div>

    <div class="function-box">
      <div class="function-item fr-ac-jsb" @click="toPage('/myTeam')">
        <div class="fr-ac">
          <img src="@/assets/img/function1.png" alt="">
          <div class="fs-16">{{ $t('myTeam') }}</div>
        </div>
        <div class="fr-ac">
          <van-icon name="arrow" size="16px" color="#CCCCCC" />
        </div>
      </div>
      <div class="function-item fr-ac-jsb" @click="toInvite">
        <div class="fr-ac">
          <img src="@/assets/img/function2.png" alt="">
          <div class="fs-16">{{ $t('inviteFriends') }}</div>
        </div>
        <div class="fr-ac">
          <div class="tip">{{ $t('inviteToEarnProfits') }}</div>
          <van-icon name="arrow" size="16px" color="#CCCCCC" />
        </div>
      </div>
      <div class="function-item fr-ac-jsb" @click="toPage('/myCollect')">
        <div class="fr-ac">
          <img src="@/assets/img/function3.png" alt="">
          <div class="fs-16">{{ $t('myCollection') }}</div>
        </div>
        <div class="fr-ac">
          <van-icon name="arrow" size="16px" color="#CCCCCC" />
        </div>
      </div>
      <!-- <div class="function-item fr-ac-jsb" @click="toPage('/myShare')">
        <div class="fr-ac">
          <img src="@/assets/img/function4.png" alt="">
          <div class="fs-16">我的分享</div>
        </div>
        <div class="fr-ac">
          <van-icon name="arrow" size="16px" color="#CCCCCC" />
        </div>
      </div> -->
      <div class="function-item fr-ac-jsb" @click="toPage('/helpList')">
        <div class="fr-ac">
          <img src="@/assets/img/function5.png" alt="">
          <div class="fs-16">{{ $t('helpCenter') }}</div>
        </div>
        <div class="fr-ac">
          <van-icon name="arrow" size="16px" color="#CCCCCC" />
        </div>
      </div>
    </div>

    <!-- 会员说明弹窗 -->
    <van-popup v-model="isShowPop" round @close="closePop()" z-index="100">
      <div class="">
        <div class="fr-ac-jsb pop-top">
          <div class="fs-16 fw-b green">{{ $t('membershipDescription') }}</div>
          <van-icon name="cross" color="#70ED24" size="20" @click="closePop()" />
        </div>
        <div class="pop-center">
          <div v-if="membershipDescription" v-html="membershipDescription"></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getUserInfo, getRules, getInviteAddress } from '@/api/member'
import web3JS from '@/utils/web3'
import { Toast, Dialog } from "vant";
import mixin from '@/utils/mixin'; //引入mixin文件
export default {
  mixins: [mixin],  // 将混入对象添加到 mixins 数组中
  data() {
    return {
      userInfo: {},
      isShowPop: false,
      membershipDescription: '',
      account: '',//用户账号
    }
  },
  computed: {

  },
  watch: {
    '$store.state.login.Token': function () {
      this.getUserInfo()
    }
  },
  mounted() {
    this.account = this.$store.state.login.account1
    if (this.$store.state.login.Token) {
      this.getUserInfo()
    }
    this.getRules()
  },
  methods: {
    checkLogin() {
      Dialog.confirm({
        message: this.$t('loginWallet'),
        confirmButtonColor: '#70ED24',
      })
        .then(() => {
          web3JS.login().then((res) => {
            this.account = res
          })
        })
        .catch(() => {

        });
    },
    login() {
      web3JS.login().then((res) => {
        this.account = res
      })
    },
    toPage(path) {
      if (!this.account) {
        this.checkLogin()
        return
      }
      this.$router.push('/mobile' + path)
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.userInfo = res.data.data.userinfo
      });
    },
    closePop() {
      this.isShowPop = false
    },
    //获取会员说明
    getRules() {
      getRules().then((res) => {
        this.membershipDescription = res.data.data
      })
    },
    //邀请
    toInvite() {
      getInviteAddress().then(async (res) => {
        // await navigator.clipboard.writeText(res.data.data.url);
        // Toast(this.$t("shareSuccessful"))
        let copy = res.data.data.url
        // 创建一个隐藏的 textarea 元素
        const textArea = document.createElement('textarea');
        textArea.value = copy;
        document.body.appendChild(textArea);

        // 选择文本
        textArea.select();
        textArea.setSelectionRange(0, 99999);  // 对于移动设备需要设置范围

        // 执行复制操作
        try {
          document.execCommand('copy');
          Toast(this.$t('shareSuccessful'))
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }

        // 移除临时的 textarea 元素
        document.body.removeChild(textArea);
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wallet-box {
  width: 100%;
  background: #2f2f2f;
  border-radius: 20px;
  // margin-top: 27px;
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 15px;

  .explain-box {
    position: absolute;
    right: 0;
    top: 0;
    // width: 100px;
    height: 38px;
    padding: 0 15px;
    background: #423E3E;
    border-radius: 0px 20px 0px 20px;
    color: #70ED24;
  }

  .info-box {
    padding: 48px 18px 20px 18px;
    color: #FFF;

    img {
      width: 61px;
      height: 61px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .member-time {
      // width: 63px;
      padding: 1px 10px;
      height: 21px;
      line-height: 21px;
      text-align: center;
      background: rgba(112, 237, 36, 0.2);
      border-radius: 10px;
      border: 1px solid #70ED24;
      color: #70ED24;
      margin-left: 10px;
    }

    .horizontal-line {
      border-top: 1px solid #000000;
      border-bottom: 1px solid #505050;
      margin-bottom: 10px;
    }

    .withdrawal-btn {
      width: 89px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      background: #70ED24;
      border-radius: 20px;
      font-weight: bold;
      font-size: 14px;
      color: #202020;
    }
  }

  .data-box {
    width: 345px;
    height: 99px;
    background: #FFFFFF;
    border-radius: 0px 0px 15px 15px;
    margin: 0 auto;

    .data-title {
      font-weight: bold;
      font-size: 16px;
      color: #212122;
      margin-bottom: 16px;
      position: relative;
      z-index: 0;
    }

    .data-title::after {
      width: 64px;
      height: 5px;
      content: '';
      background: #70ED24;
      border-radius: 5px;
      position: absolute;
      z-index: -1;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.function-box {
  width: 355px;
  background: #FFFFFF;
  border-radius: 20px;
  border: 6px solid #2f2f2f;
  padding: 7px 10px;

  .function-item {
    padding: 7px 0;
    border-bottom: 1px solid #EEEEEE;

    img {
      width: 27px;
      height: 27px;
      margin-right: 11px;
    }

    .tip {
      color: #999999;
      margin-right: 6px;
    }
  }

  .function-item:last-child {
    border-bottom: none;
  }
}

.pop-top {
  width: 355px;
  padding: 18px 16px;
  background: #000000;
}

.pop-center {
  width: 355px;
  padding: 47px 30px 30px;
}
</style>
