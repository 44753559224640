<template>
    <div class="fr-ac-jsb" style="margin: 100px 40px 80px;">
        <div class="fc-ac">
            <img class="logo" style="margin-bottom: 20px;" src="@/assets/img/logo.png" alt="">
            <div class="tip" style="font-size: 24px;font-weight: bold;">- {{ $t('riskTip') }} -</div>
        </div>
        <div class="fr-ac">
            <img class="code" style="margin-right: 18px;" src="@/assets/img/code.png" alt="">
            <img class="advertisement" src="@/assets/img/advertisement.png" alt="">
        </div>
    </div>
</template>

<script>
import store from "@/store";
import web3JS from '@/utils/web3'
export default {
    data() {
        return {

        }
    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped></style>